import React, { useEffect, useState } from "react";
import Logo from "./CommonComponent/Logo"
/*import RegisterForm from "./Components/RegisterComponent/RegisterForm"*/
import Login from "./Components/LoginComponent/Login";
/*import Register from "./Components/RegisterComponent/Register"*/
import Main_header from "./Components/Header/Header";
import Main_footer from "./Components/Header/Footer";
/*import Main_sidebar from "./Components/Header/Sidebar"*/
import Home_page from "./Components/HomeComponent/Home";
import MyRoutes from './MyRoutes';
import { useNavigate } from 'react-router-dom'
import firebase from "./firebase";
import { createTheme } from '@material-ui/core/styles'
//import Snackbar from '@mui/material/Snackbar';
import { Button, Snackbar ,Checkbox} from "@material-ui/core";
import { requestForToken, onMessageListener } from './firebase';
import { getTokenInApp } from "./firebase";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CToast } from '@coreui/react'
//import CToast from '@coreui/react-pro/src/components/toast/CToast'
import { CToastBody } from '@coreui/react'
import { CToastClose } from '@coreui/react'
import { CButton } from '@coreui/react'
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert';
import useSound from 'use-sound';
//import boopSfx from 'sounds/';
import boopSfx from './Assets/boop.mpeg';
import sound from './Assets/Callring.mp3'
// import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const navigate = useNavigate()
  const location = useLocation();
  const authurls = ['/', '/otp', '/register', '/register-form', '/otp-phone', '/InterpreterLogin']
  const [open, setOpen] = React.useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [alertShow, setalertShow] = useState(false);
  const [notificationBody,setnotificationBody] = useState('');
  const [notificationTitle, setnotificationTitle] = useState('');
  //const [alertShow, setalertShow] = useState(false);
  // useEffect(() => {
  getTokenInApp(setTokenFound);
  // },[])



  const handleClick = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const [playActive] = useSound(
  //   boopSfx,
  //   { volume: 0.25 }
  // );
  const [playOn] = useSound(sound,{ volume: 0.25 });
  const [playOff] = useSound(sound,{ volume: 0.20 });

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    setalertShow(true)
    // useof()
    playOn()
    setnotificationTitle(payload.notification.title)
    setnotificationBody(payload.notification.body)
    console.log('payload',payload);
  }).catch(err => console.log('failed: ', err));

  if (authurls.includes(location.pathname)) {
    if (localStorage.getItem("accessToken") != null) {
      if (localStorage.getItem("callSetupid")) {
        navigate('/InterpreterFeedbackform')
      }
      else {
        navigate('/home')
      }

    }

    return (
      <>
        <MyRoutes />
      </>
    );
  }
  else {
    if (localStorage.getItem("accessToken") == null) {
      navigate('/')
    }
    const reDirectNotification=()=>{
      setalertShow(false)
       playOff()
      navigate('/home')
    }
    return (
      <>

        {/* <Logo/> */}
        {/* <Login/> */}
        {/* <div>
        <button onClick={notify}>Notify!</button>
        <ToastContainer />
      </div> */}
{/* <<<<<<< HEAD */}
      {/* <SweetAlert
=======
    {/* <SweetAlert
>>>>>>> 581d7e97bd5f63347174eddcd1956deb33da9fe3
     customButtons={
     <Button onClick={reDirectNotification}>Click</Button>
      }
    title="Woot!"
    style={{
    //position: "fixed",
    top: "15px",
    left: "25px",
    position: "none !important",
    justifyContent: "center",
    marginTop: "12px"
   }}
>
  I did it!
</SweetAlert> */}
  {alertShow ? 
  <SweetAlert
    title={notificationTitle}
   // onConfirm={reDirectNotification}
    style={{top: "15px",left: "25px",position: "none !important",
    justifyContent: "center",
    marginTop: "12px"}}
   >{notificationBody}
   customButtons={
   
      <Button onClick={reDirectNotification}>Click</Button>
      
    
  }
 </SweetAlert> : ''}
 {/* <Checkbox
      name="demo-checkbox"
      checked={isChecked}
      size={24}
      label="I agree to self-isolate"
      onChange={() => setIsChecked(!isChecked)}
      
      onMouseUp={() => {
        isChecked ? playOff() : playOn();
      }}
    /> */}
  

        
        <Main_header />
        <MyRoutes />
        <Main_footer />
        </>
    );
  }

}

export default App;
