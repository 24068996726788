import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import {ReactComponent as Icon_Contact_video} from "../../../Assets/call-a-person-video-call.svg";
import {ReactComponent as Icon_userplus} from "../../../Assets/user-plus.svg";
import Spinner_150 from "../../../Assets/Spinner-1s-150px.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../../Service/CommonService';
import {getprofile,getAuth} from '../../../Service/Helper';

const Call_content = (props) => {
	console.log('selectedmember------',props.selectedmember)
	const [is_Callloaded,setCallLoaded]=useState(false);
	const [mycontacts,setmycontacts]=useState([]);
	const [query,setQuery]=useState("");
	const authdata=getAuth()
	const userid=authdata['_id'];
	// const [selectedmember,setselectedmember]=useState({/*circle_id:"",*/member:[]/*,circleDetails:[]*/})
	// const userid=8;
	const contactDetails=[];
	useEffect(() => {
	    getMyContact();
	}, []);
	const getMyContact = async () => {
		setCallLoaded(true);
	    var query = "user_id="+userid+'&contactDetails=[]'
	    const response = await postDataFromApi('add/contactDetailsNew', query);
	    if(response.data.code && response.data.data!=null){
	    	setmycontacts(response.data.data);
	    }
        setCallLoaded(false);
	}
    
 //    const doselectedmember=(contact,index)=>{
 //    	console.log(contact)
	// 	let selected=selectedmember.member
	// 	//let circleDetails=selectedmember.circleDetails
	// 	if(selected.includes(contact._id)){
	// 		const i = selected.indexOf(contact._id);
	// 		if (i > -1) {
	// 			selected.splice(i, 1);
	// 			//circleDetails.splice(i,1)
	// 		}
	// 	}
	// 	else{
	// 		selected.push(contact._id)
	// 		/*var newcontact={
	// 			'name':contact.contact_name,
	// 			'contact_number':contact.contact_number
	// 		}
	// 		circleDetails.push(newcontact)*/
	// 	}
	// 	console.log(selected)
	// 	setselectedmember((formData) => ({
 //            ...formData,
	// 		member:selected,
	// 		/*circleDetails:circleDetails*/
 //        }));

	// 	console.log('selectedmember',selectedmember)
	// }

	// console.log("query",query)
	const querydata=mycontacts.filter(mycontacts=>mycontacts.contact_name.toLowerCase().includes(query.toLowerCase()))
	// console.log("querydata",querydata)

	return (
		<div className="call-person-tab-content extra-shadow-bottom">
			<div className="call-person-tab-content-inner">
				<div className="call-person-tab-header">
					<h3>Select people for conference</h3>
				</div>
				<div className="call-person-search-form">
					<form>
						<label>
							<img src={SearchIcon}/>
							<input
							type="text"
							name="search-contacts"
							className="search-contacts"
							id="search-contacts"
							placeholder="Search from contacts, groups and calls"
							onChange={(e)=>{setQuery(e.target.value)}}
							/>
						</label>
						<input type="submit" id="search-contacts-btn"/>
					</form>
				</div>
				{/*<div className="call-person-bottom-title">
					<p>Select people for conference</p>
				</div>*/}
				<div className="con_callperson vertical_scrollbar">
				{ is_Callloaded ? <img className="loading_spinner" src={Spinner_150} />   : ''}
				{
						(querydata.length>0) ?
						querydata.map(function(contact, i){
					        return (
					        	<div className="call-person-bottom-content" key={'mycontacts-'+i}>
						<div className="call-person-details">
							<div className="call-person-img">
								<p className="single_profinitial">{getprofile(contact.contact_name,'')}</p>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>{contact.contact_name}</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready! {contact._id}</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:"  onClick={() => props.doselectedmember(contact,i) } className={props.selectedmember.member.includes(contact._id)?'Icon_member_added' : 'Icon_member'} >
									<Icon_userplus className="Icon_Contact_video"/>
								</a>
							</div>
						</div>
					</div>
					);
					    })
				:
					(<div className="single_contact">
						<p className="nodata_found">No Data Found</p>
						                      
					</div>)    
				}
				</div>
			</div>
		</div>
	)
}

export default Call_content;