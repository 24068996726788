import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {mainURL} from "../../Service/CommonService"


const Razorpay_page = () => {

	/*const CourseCard = [{
		  courseName: "Complete React Native Mobile App developer - Build 10 apps", 
		  courseDetails:"2 Free + 92 Paid",
          coursePrice:"2,999",
          courseDiscountedPrice:"199",
          courseDiscount:"93",
    }]*/
    /*const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });*/
  console.log("razorpayKeyBaseURL")
  console.log("razorpayKeyBaseURL",mainURL)
  var razorpayKey = "rzp_test_LomgTJap9dTFn3";

    if (
	mainURL.includes("prodapi") || mainURL.includes("live")
    ) {
	razorpayKey = "rzp_live_Iym4vheOZ4xktC";
	// console.log("other mainURL");
    } else {
		// console.log("testing or staging mainURL");
		razorpayKey = "rzp_test_LomgTJap9dTFn3";
    }

  const options = {
        // key: 'rzp_test_LomgTJap9dTFn3',
        key: razorpayKey,
        amount: '100', //  = INR 1
        name: 'test shop',
        description: 'some description',
        image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        handler: function(response) {
            alert(response.razorpay_payment_id);
        },
        prefill: {
            name: 'Sufiyan',
            contact: '9999999999',
            email: 'demo@demo.com'
        },
        notes: {
            address: 'some address'
        },
        theme: {
            color: 'blue',
            hide_topbar: false,
        }
    };

    const openPayModal = () => {
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

   return (
       <div className="page-main">
			<div className="page-main-inner">
			    <Grid container spacing={3} className="main-grid">
				   Razorpay
				   <article className="card">
				      <div className="card-content">
				        <header className="card-header">
				          <h5>Testing Payment</h5>
				        </header>

				        <button
				          type="button"
				          className="course-payment-button"
				          onClick={openPayModal}
				        >
				          Buy Now
				        </button>
				      </div>
				    </article>
				</Grid>
			</div>
		</div>	
   	)
}
export default Razorpay_page;