import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabsContents from "./TabsContents";
import ProccessingCall from "./ProccessingCall";
import ViewSelectedTabs from "./ViewSelectedTabs";
import NotconnectedCall from "./NotconnectedCall";
import { ReactComponent as TabImg_1 } from "../../Assets/call-a-person-tab-1.svg";
import { ReactComponent as TabImg_2 } from "../../Assets/call-a-person-tab-2.svg";
import { ReactComponent as TabImg_3 } from "../../Assets/call-a-person-tab-3.svg";
import { ReactComponent as TabImg_4 } from "../../Assets/call-a-person-tab-4.svg";
import { ReactComponent as Icon_userplus } from "../../Assets/user-plus.svg";
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Icon_user_plus from "../../Assets/Icon-user-plus.svg";
import { ReactComponent as Icon_multipleuser } from "../../Assets/vector-multiple-user.svg";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const Conference_page = () => {
  const navigate = useNavigate();
  const [tabvalue, setTabvalue] = useState(0);
  const [is_selected, setSelected] = useState(false);
  const [is_preccessed, setProccessed] = useState(false);
  const [is_notconnected, setNotconnected] = useState(false);
  const [languages, setlanguages] = useState([]);
  const [isvideocallstarted, setisvideocallstarted] = useState(false);
  const[toAddUserName,setToAddUserName]=useState("")
  const[toAddUserContactNumber,setToAddUserContactNumber]=useState("")
  const [notInyourContact, setnotInyourContact] = useState({
    name: "",
    contact_number: "",
  });

  const tabchange = (newValue) => {
    setTabvalue(newValue);
  };
  const [selectedmember, setselectedmember] = useState({
    member: [],
    contactdetails: [],
    contactNamesList:[],
    contactNumbersList:[]
  });

  const initialValues = { name: "", contact_number: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [issubmit, setIssubmit] = useState(false);
  const [toadd, setToadd] = useState(false);
  const [pagevar, setPagevar] = useState("");
  const [personName,setPersonName]= useState();
  const [contact,setContact]= useState();


  useEffect(() => {
    getlanguages();
  }, []);
  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setlanguages(response.data.data);
    }
  };
  const doselectedmember = (contact, index, page = "") => {
    console.log("contact", contact);

    let selected = selectedmember.member;
    let singlecontactdetails = selectedmember.contactdetails;
    let contactNamesdetails = selectedmember.contactNamesList;
    let contactNumbersdetails = selectedmember.contactNumbersList;

    if (selected.includes(contact._id)) {
      const i = selected.indexOf(contact._id);
      if (i > -1) {
        selected.splice(i, 1);
        singlecontactdetails.splice(i, 1);
        contactNamesdetails.splice(i, 1);
        contactNumbersdetails.splice(i, 1);
      }
    } else {
      selected.push(contact._id);
      singlecontactdetails.push(contact);
      contactNamesdetails.push(contact.contact_name)
      contactNumbersdetails.push(contact.contact_number)
    }
    console.log(selected);
    if (page == "ViewSelectedTabs") {
      if (selected.length == 0) {
        setSelected(false);
      }
    }
    setselectedmember((formData) => ({
      ...formData,
      member: selected,
      contactdetails: singlecontactdetails,
    }));

    console.log("selectedmember", selectedmember);
  };

  const NotInList = (e, page = "") => {
    // console.log("inside notinlist pagevar",page)
    setFormErrors(validate(formValues));
    setIssubmit(true);
    setPagevar(page);
    addOrRemovePersonFunction()
    setToAddUserContactNumber("")
    setToAddUserName("")

    // if (selected.includes("custom-1")) {
    //   const i = selected.indexOf("custom-1");
    //   if (i > -1) {
    //     selected.splice(i, 1);
    //     singlecontactdetails.splice(i, 1);
    //   }
    // } else {
    //   selected.push("custom-1");
    //   singlecontactdetails.push(data);
    // }
    // console.log(selected);
    // if (page == "ViewSelectedTabs") {
    //   if (selected.length == 0) {
    //     setSelected(false);
    //   }
    // }
    // setselectedmember((formData) => ({
    //   ...formData,
    //   member: selected,
    //   contactdetails: singlecontactdetails,
    // }));

    // console.log("selectedmember", selectedmember);
  };
  // const onCustomContactChange = (e) => {
  //   var value = e.target.value;
  //   console.log("value", value);
  //   setFormErrors(validate(formValues));
  //   const { name } = e.target;
  //   setFormValues({ ...formValues, [name]: e.target.value });
  //   setnotInyourContact((formData) => ({
  //     ...formData,
  //     [e.target.name]: value,
  //   }));
  // };
  const onCustomContactChange = (e) => {
    var value = e.target.value;
    console.log("value", value);
    setFormErrors(validate(formValues));
    const { name } = e.target;

    // console.log("e.target.name====>",e.target.name)
    setFormValues({ ...formValues, [name]: e.target.value });
    setnotInyourContact((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
    if(e.target.name=="name"){
      // console.log("detected name")
      setToAddUserName(e.target.value)
    }else{
      // console.log("detected number")
      setToAddUserContactNumber(e.target.value)
    }
  };

  useEffect(()=>{
    console.log("formValues useeff---->",formValues)
    console.log("formValues useeff person---->",formValues.name)
    setPersonName(formValues.name)
    setContact(formValues.contact_number)
    console.log("formValues useeff number---->",formValues.contact_number,(formValues.contact_number).length)
    
  },[formValues])

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIssubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.contact_number) {
      errors.contact_number = "Contact number is required";
    }
    return errors;
  };

  // useEffect(() => {
  //   console.log("formErrors", formErrors);
  //   if (Object.keys(formErrors).length === 0 && issubmit) {
  //     // console.log("formValues", formValues);
  //     // setToadd(true);
  //     let selected = selectedmember.member;
  //     let singlecontactdetails = selectedmember.contactdetails;
  //     let data = [];
  //     data["contact_name"] = notInyourContact.name;
  //     data["contact_number"] = notInyourContact.contact_number;
  //     data["_id"] = "custom-1";

  //     if (selected.includes("custom-1")) {
  //       const i = selected.indexOf("custom-1");
  //       if (i > -1) {
  //         selected.splice(i, 1);
  //         singlecontactdetails.splice(i, 1);
  //       }
  //     } else {
  //       selected.push("custom-1");
  //       singlecontactdetails.push(data);
  //     }
  //     console.log(selected);
  //     if (pagevar == "ViewSelectedTabs") {
  //       if (selected.length == 0) {
  //         setSelected(false);
  //       }
  //     }
  //     setselectedmember((formData) => ({
  //       ...formData,
  //       member: selected,
  //       contactdetails: singlecontactdetails,
  //     }));

  //     console.log("selectedmember", selectedmember);
  //   }
  // }, [formErrors, pagevar]);

  function addOrRemovePersonFunction(){
    console.log("formErrors", formErrors);
    if (Object.keys(formErrors).length === 0 && issubmit) {
      let selected = selectedmember.member;
      let singlecontactdetails = selectedmember.contactdetails;
      let contactNamesdetails = selectedmember.contactNamesList;
      let contactNumbersdetails = selectedmember.contactNumbersList;

      let data = {};
      data["contact_name"] = notInyourContact.name;
      data["contact_number"] = notInyourContact.contact_number;
      // data["_id"] = "custom-1";
      data["_id"] = selectedmember.member.length;
      var newSetId=selectedmember.member.length
      console.log("contactNumbersdetails===>",contactNumbersdetails,"contactNamesdetails===>",contactNamesdetails)

      if (contactNamesdetails.includes(notInyourContact.name)) {
        alert(`${data.contact_name} name already exist!`)

      }else if(contactNumbersdetails.includes(notInyourContact.contact_number)){
        alert(`${data.contact_number} contact number already exist!`)
 
      } else {
        selected.push(newSetId);
        singlecontactdetails.push(data);
        contactNumbersdetails.push(data.contact_number)
        contactNamesdetails.push(data.contact_name)
        console.log("singlecontactdetails",singlecontactdetails,contactNamesdetails.includes(notInyourContact.name),contactNumbersdetails.includes(notInyourContact.contact_number),notInyourContact.name,notInyourContact.contact_number)
      }
      console.log(selected);
      if (pagevar == "ViewSelectedTabs") {
        if (selected.length == 0) {
          setSelected(false);
        }
      }
      setselectedmember((formData) => ({
        ...formData,
        member: selected,
        contactdetails: singlecontactdetails,
      }));

      console.log("selectedmember", selectedmember);
    }
  }

  useEffect(()=>{
    setIssubmit(true);
  },[])

  function checkifSelected() {
    if (selectedmember.member.length != 0) {
      setSelected(true);
    } else {
      setSelected(false);
      swal({
        // title: "client added!",
        text: "Please Select people for conference",
        icon: "error",
      });
    }
  }
  return (
    <>
      <div className="page-main">
        <div className="page-main-inner conference">
          {!is_notconnected ? (
            <Box>
              {!is_preccessed ? (
                <Grid container spacing={3} className="main-grid">
                  <Grid
                    item
                    md={!is_selected ? 6 : 8}
                    className="call-person-profile"
                  >
                    <div
                      className="box-shadow text-center radius-8px"
                      style={{ height: "100%" }}
                    >
                      <div className="call-person-profile-inner">
                        <Tabs
                          value={tabvalue}
                          onChange={(event, newValue) => tabchange(newValue)}
                          className="call-person-tab-header custom_scrollbar hidetab"
                        >
                          <Tab label="Call" icon={<TabImg_1 />} />
                          {/*<Tab label="Recent Calls"
										icon={<TabImg_2/>}
										/>
										<Tab label="Contacts"
										icon={<TabImg_3/>}
										/>
										<Tab label="My Groups"
										icon={<TabImg_4/>}
										/>*/}
                        </Tabs>
                      </div>
                      {is_selected ? (
                        <ViewSelectedTabs
                          tabvalue={tabvalue}
                          setProccessed={setProccessed}
                          selectedmember={selectedmember}
                          doselectedmember={doselectedmember}
                          setSelected={setSelected}
                        />
                      ) : (
                        <TabsContents
                          tabvalue={tabvalue}
                          selectedmember={selectedmember}
                          doselectedmember={doselectedmember}
                        />
                      )}
                    </div>
                  </Grid>
                  {!is_selected ? (
                    <Grid item md={6} className="call-person-form">
                      <div className="box-shadow radius-8px">
                        <div className="call-person-form-inner">
                          <div className="call-person-form-title">
                            <h3>Not in your contact list?</h3>
                          </div>
                          <div className="call-person-form-main">
                            <div className="call-person-form-main-inner">
                              <form onSubmit={handleSubmit}>
                                <div className="form-div">
                                  <label>Name of Person</label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="name-of-person"
                                    // value={formValues.name}
                                    value={toAddUserName}
                                    id="name"
                                    placeholder="Enter name"
                                    onChange={(e) => onCustomContactChange(e)}
                                  />
                                </div>
                                <p style={{ color: "red" }} className={(formErrors.name)?"pform1":"pform2"}>
                                  {/* {formErrors.name} */}
                                  {
                                (personName=="")?formErrors.name:""
                              }
                                </p>
                                <div className="form-div">
                                  <label className="img-label">
                                    <img src={Flag_Ind} />
                                    <input
                                      type="number"
                                      name="contact_number"
                                      className="phone-number"
                                      id="contact_number"
                                      placeholder="+91  Enter phone number"
                                      // value={formValues.contact_number}
                                      value={toAddUserContactNumber}
                                      onChange={(e) => onCustomContactChange(e)}
                                    />
                                  </label>
                                </div>
                                <p style={{ color: "red" }} className={(formErrors.name)?"pform1":"pform2"}>
                                {
                                (contact=="")?formErrors.contact_number:""
                              }
                                  {/* {formErrors.contact_number} */}
                                </p>
                                {/* <div className="form-div">
													<label>Language</label>
													<select
													name="language"
													className="language"
													id="language">
														<option value="default" selected disabled>Select language of the person</option>
														{
															languages.map(function(language,i){
																return (<option value="{language._id}">{language.language}</option>)
															})
														}
														
													</select>
												</div> */}

                                <div
                                  className="form-div submit-call"
                                  style={{ height: "60px" }}
                                >
                                  <a
                                    href="javascript:"
                                    onClick={(e) => NotInList(e)}
                                    // className={
                                    //   // selectedmember.member.includes("custom-1")
                                    //     ? "Icon_member_added"
                                    //     : "Icon_member"
                                    // }
                                    className={
                                      (selectedmember.contactNumbersList.includes(toAddUserContactNumber) || toAddUserContactNumber =="")
                                        ?"Icon_member"
                                        :"Icon_member_added"
                                    }
                                  >
                                    {/* <img src={Icon_user_plus} className={selectedmember.member.includes('custom-1')?'Icon_member_added' : 'Icon_member'} /> */}
                                    <Icon_userplus className="Icon-Plus-Contact" />
                                  </a>
                                  {/* <input
																		type="button"
																		name="video-call"
																		className="video-call"
																		id="video-call"
																		onClick={() => NotInList()}
																	/> */}
                                </div>
                                <Button
                                  className="primary"
                                  variant="text"
                                  style={{ marginTop: "4%" }}
                                  onClick={() => checkifSelected()}
                                >
                                  <Icon_multipleuser />
                                  View Selected
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <ProccessingCall
                  setNotconnected={setNotconnected}
                  selectedmember={selectedmember}
                  doselectedmember={doselectedmember}
                  setProccessed={setProccessed}
                  setSelected={setSelected}
                  isvideocallstarted={isvideocallstarted}
                />
              )}
            </Box>
          ) : (
            <NotconnectedCall
              selectedmember={selectedmember}
              doselectedmember={doselectedmember}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Conference_page;
