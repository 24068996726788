import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { postDataFromApi, getDataFromApi, putDataFromApi } from '../../../Service/CommonService';
import Grid from '@material-ui/core/Grid';
import Input from '../../../CommonComponent/Input';
import Spinner_150 from "../../../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_profile } from "../../../Assets/home-profile-icon.svg";
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import leftIcon from "../../../Assets/Left.png"
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import { ReactComponent as TabImg_1 } from "../../../Assets/Phone.svg";
import MemberIcon from "../../../Assets/add_member.jpeg";
import MemberDlt from "../../../Assets/member_dlt.jpeg";
import { ReactComponent as Icon_group } from "../../../Assets/group_icon.svg"
import { ReactComponent as Blue_Icon } from "../../../Assets/blue-member-icon.svg"
// import {getDataFromApi,putDataFromApi} from '../../../Service/CommonService';
import { Button, TextField } from "@material-ui/core";
import Popup from 'reactjs-popup';
import { ReactComponent as Icon_closegrey } from "../../../Assets/close-grey.svg";
import { ReactComponent as Member_Icon } from "../../../Assets/plus-member.svg";
import style from '../../../Assets/css/substyle.css'
import MultipleUser from "../../../Assets/multiple-user.svg";
import { ReactComponent as Icon_multipleuser } from "../../../Assets/vector-multiple-user.svg";
import { getprofile, getAuth } from '../../../Service/Helper';
import { ReactComponent as Icon_userplus } from "../../../Assets/user-plus.svg";
import ProccessingCall from './ProcessingCall';
import { getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails,CallSetupForSupport,custoclearinterval,BaseUrl,CallStatusUpdateData} from "../../../Service/videoskp";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {
	Routes,
	Route,
	NavLink,
	useNavigate ,
	HashRouter
} from "react-router-dom";
import $ from 'jquery';
const MyGroups_content = (props) => {
	const authdata = getAuth()
	// const userid=8;
	const userid = authdata['_id'];
	const [open, setOpen] = useState(false);
    const [isvideocallstarted,setisvideocallstarted]=useState(false)
	const [is_selected,setSelected]=useState(false)
	const [openmember, setOpenmember] = useState(false);
	const [addmemberpoup, setaddmemberpoup] = useState(false);
	const [is_grouped, setis_grouped] = useState(false);
	const [is_member, setis_member] = useState(false);
	const [is_Contactloaded, setContactLoaded] = useState(false)
	const [mycontacts, setmycontacts] = useState([])
	const [isclicked, setisclicked] = useState(false)
	const [group, setgroup] = useState([])
	const [groupDetails, setgroupDetails] = useState([])
	const [findgroup, setfindgroup] = useState([])
	const contactDetails = [];
	const [formdata, setfromdata] = useState({ user_id: userid, circle_title: "",language:"1" })
	const [isOpen, setisOpen] = useState(false)
	const [selectedmember, setselectedmember] = useState({ circle_id: "", member: [], circleDetails: [] })
	const [selectedgroup, setselectedgroup] = useState({})
	const [callAttendeeDetails, setcallAttendeeDetails] = useState([])
	const [is_notconnected,setNotconnected]=useState(false)
	const [is_preccessed,setProccessed]=useState(false)
	const [CallResponseData,setCallResponseData] = useState({})
    const [intervalId, setIntervalId] = useState(false);
	const [clickedcall, setclickedcall] = useState({})
	const [newIntervalId,setnewIntervalId]=useState('');
	const [isCallReceived,setisCallReceived] = useState(false)
	const [token,settoken]=useState([])
	const[MeetingData,setMeetingData]=useState([])
	const[MeetingId,setMeetingId]=useState('')
	const navigate = useNavigate();
	const [query,setQuery]=useState("")
	// const [addedgrp,setaddedgrp]=useState({})
	var processed=false;
    var singlenewIntervalId  ="";
	useEffect(() => {
		getMyContact();
		// createGroup()
		getgroups()
	}, []);
	const getMyContact = async () => {
		setContactLoaded(true);
		var query = "user_id=" + userid + '&contactDetails=[]'
		const response = await postDataFromApi('add/contactDetailsNew', query);
		if (response.data.code && response.data.data != null) {
			setmycontacts(response.data.data);
			getgroups()
		}
		setContactLoaded(false);

	}
	const createGroup = async () => {
		setContactLoaded(true);
		const response = await postDataFromApi('add/userCircle', formdata);
		if (response.data.code && response.data.data != null) {
			setgroup(response.data.data);
			getgroups()
			setselectedmember((formData) => ({
				...formData,
				circle_id: response.data.data._id
			}));

		}
		setaddmemberpoup(true)
		// setContactLoaded(false);

	}
	const getgroups = async () => {
       const response = await getDataFromApi('find/userCircleUsingUserId/' + userid, []);
		if (response.data.code && response.data.data != null) {
			setfindgroup(response.data.data);
		}
	}
	const addgroupdetails = async () => {

		const response = await postDataFromApi('add/userCircleDetails', selectedmember);
		if (response.data.code) {
			setgroupDetails(response.data.data);
			setaddmemberpoup(false)
			setis_member(false)
		}

	}
	const closeModal = () => {
		setOpen(false);
	}
	const closeModalMember = () => {
		setOpenmember(false);
	}
	const openModal = () => {
		setOpen(true);
	}
	const showdetails = (index) => {
		setis_grouped(true);
		setselectedgroup(findgroup[index])
		console.log('groupdetails', findgroup[index])
		setselectedmember((formData) => ({
			...formData,
			circle_id: findgroup[index]._id,
			member: [],
			circleDetails: []

		}));
	}
	const showmember = () => {
		setis_member(true);
		setis_grouped(false);
	}
	const mainpage = () => {
		setis_member(false);
		setis_grouped(false);
	}
	const backtoMemberDetails = () => {
		setis_member(false);
		setis_grouped(true);
	}
	const ongrpchange = (e) => {
		console.log('e.targe', e.target)
		setfromdata((formData) => ({
			...formData,
			[e.target.name]: e.target.value,

		}));
	}
	const oncreategrp = () => {

		setaddmemberpoup(true)
		// console.log('formdate',formdata)
	}
	const doselectedmember = (contact, index) => {
		let selected = selectedmember.member
		let circleDetails = selectedmember.circleDetails
		if (selected.includes(contact._id)) {
			const i = selected.indexOf(contact._id);
			if (i > -1) {
				selected.splice(i, 1);
				circleDetails.splice(i, 1)
			}
		}
		else {
			selected.push(contact._id)
			var newcontact = {
				'name': contact.contact_name,
				'contact_number': contact.contact_number
			}
			circleDetails.push(newcontact)
		}
		console.log(selected)
		setselectedmember((formData) => ({
			...formData,
			member: selected,
			circleDetails: circleDetails
		}));

		console.log('selectedmember', selectedmember)
	}

	const removerelectedmember = () => {

	}
	const getMeetingData = async (token) => {
		var query = ""
		const response = await createMeeting(token);
		console.log('response',response)
		if(response &&response!=null){
			setMeetingData(response)
			setMeetingId(response.meetingId)
			console.log(response.meetingId)
	          //startmeeting(response.meetingId)
	          settingCall(response)
	      }


	}

	const settingCall = async (data) => {
		console.log('data',data)
		var contactDetailsArray=[];

		contactDetailsArray=[{
			mobile_number:authdata['mobile_number'],
	  		name:authdata['first_name'],

	  	}]

	  	var inputData = JSON.stringify({
			"user_id": userid,
			"call_type": "1",
			"language_id": formdata.language ? formdata.language : authdata['language_id'],
			"purpose_id": "1",
			"meetingDetails": data,
			"contactDetails": contactDetailsArray,
			"user_type": authdata['user_type'],
			"individual_user_type": authdata['user_category'],
			"is_same_gender": "0",
			"initiator_gender": authdata['gender'],
			"initiatorFullName": authdata['first_name'],
			"source":"web"
	  	})
		  

	  	console.log('inputData',inputData)

	  	/* call setup API */

	  	const callResponse = await callSetup(inputData);
	  	setCallResponseData(callResponse)
		//   setProccessed(true)
	  	console.log('callResponse',callResponse)
		//console.log('callResponse',callResponse.data.meetingId)

		if(callResponse && callResponse.code==1){
			if(!intervalId){
				setTimeout(function () {
					if(!isCallReceived){
						CallStatusUpdateData(callResponse.data._id,'7','6')	
						clearInterval(singlenewIntervalId);
						setIntervalId(true);
					
						setNotconnected(true)
					}
				}, 30000);
				singlenewIntervalId=setInterval(function () {
					console.log('every 3 seconds')
					startTimer(callResponse)
				}, 3000);
				setnewIntervalId(singlenewIntervalId)
			}
			//clearInterval(singlenewIntervalId);
			/*if (intervalId) {
		      clearInterval(singlenewIntervalId);
		      
		      console.log("  cc clear")
		      
		  }*/


		}else{
			alert(callResponse.message)
			CallStatusUpdateData(callResponse.data._id,'7','6')	
			clearInterval(singlenewIntervalId);
		}

		// below code need to add for web

		// else if (callResponse.code === 2) {
		// 	//setCallSetupId(callResponse.data._id)
		// 	var systemLanguageId = callResponse.systemGeneratedLanguageId;
		// 	console.log('systemLanguageId', systemLanguageId);
		// 	clearCurrentInterval()
		// 	if (systemLanguageId !== 0) {
		// 		var index = languageArr.findIndex(item => item._id === systemLanguageId)
		// 		console.log('index', index);
		// 		setLanguageId(systemLanguageId)
		// 		console.log('language', language_array);
		// 		setLanguage(language_array[index].value)
		// 		setIsLanguagePopupVisible(true)
		// 	} else {
		// 		Alert.alert('Language Required', 'Please select language to initiate call',
		// 			[{
		// 				style: "cancel",
		// 				onPress: () => {
		// 					setIsLanguagePopupVisible(true)
		// 				},
		// 				text: "Ok",
		// 			}])
		// 	}
		// } else if (callResponse.code === 3) {
		// 	clearCurrentInterval()
		// 	Alert.alert('Sorry', 'There is no interpreter available.',
		// 		[{
		// 			style: "cancel",
		// 			onPress: () => {
		// 				navigation.goBack();
		// 			},
		// 			text: "Ok",
		// 		}])
		// } else {
		// 	clearCurrentInterval()
		// 	Alert.alert('Sorry', callResponse.message,
		// 		[{
		// 			style: "cancel",
		// 			onPress: () => {
		// 				navigation.goBack();
		// 			},
		// 			text: "Ok",
		// 		}])
		// }
	}
    
    const startTimer = async (data) => {
		/*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
		console.log('console.log(response)',response1)*/
		const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'],authdata['user_type'], token);
		console.log('startTimer',response)

		if (response && response.data !== "data not found") {
			console.log(newIntervalId);
			// response.data.status = 2
			if (response.data.status == 2) {
				if(!processed){
					processed=true;
					clearInterval(newIntervalId);
					setIntervalId(true);
					CallStatusUpdateData(data.data._id,'3','3')
					startmeeting(data.data.meetingId,data.data._id)
					setisCallReceived(true)
				}
			}
		}


	}	

	const startmeeting=async(meetingId,callSetupid)=>{
		localStorage.setItem('callSetupid', callSetupid)
	    //here call start meeting api before initiate meeting our own api will be call
	    const config = {
	    	name: authdata['first_name'],
	    	meetingId: meetingId,
	    	apiKey:sdkAPIKey,
	    	// joinScreen: {
	    	// 	visible: false,

	    	// },
	    	containerId: "meeting-container-id",
	    	micEnabled: true,
	    	webcamEnabled: true,
	    	participantCanToggleSelfWebcam: true,
	    	participantCanToggleSelfMic: true,

	    	chatEnabled: true,
	    	screenShareEnabled: true,
	    	canChangeLayout: true,
			redirectOnLeave:window.location.origin,
	    	// redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
	    	joinScreen: {
	    		visible: false,
	    	},
	    };

	    const meeting = new VideoSDKMeeting();
	    meeting.init(config);
	   	$(".full-screen").insertAfter(".page-main");
	   	
	    setisvideocallstarted(true)
	   	setNotconnected(true)

	}

	const CallStatusUpdate = async (buttonType)=>{

		clearInterval(newIntervalId);
		setIntervalId(true);

		if(buttonType=='cancel'){
			console.log('CallResponseData as',CallResponseData)
			const response = await CallStatusUpdateData(CallResponseData.data._id,'6','6');
			console.log('cancel',response)
			if(response && response.code){
				setNotconnected(false)
				setNotconnected(false)
				setProccessed(false)
				setSelected(false)
				clearInterval(singlenewIntervalId);
				navigate('/Home')

			}

		}else if(buttonType=='callback'){
			const response = await CallStatusUpdateData(CallResponseData.data._id,'5','6');
			console.log(response)
		}
	}

	const doGroupCall=(selectedgroup=[])=>{
		var userdata=[];
		selectedgroup.circle_details_data.map((user,i)=>{
			user['user_docs']=user.user_masters
			userdata.push(user)
		})
		console.log('callAttendeeDetails--',userdata)
		setcallAttendeeDetails(userdata)
		setNotconnected(true)
	}
	// console.log("findgroup",findgroup)
	// console.log("query",query)

	const querydata=findgroup.filter(findgroup=>findgroup.circle_title.toLowerCase().includes(query.toLowerCase()))
	// console.log("querydata",querydata)

	return (
		<>
		{/* {!is_notconnected ? 
	
		(<div className="call-person-tab-content headercall-mygroups">
			<div className="call-person-tab-content-inner">
				<div className="call-person-search-form">
					<form className="search-icon-group">
						<label>
							<img src={SearchIcon} />
							<input
								type="text"
								name="search-contacts"
								className="search-contacts"
								id="search-contacts"
								placeholder="Search from contacts, groups and calls"
								onChange={(e)=>setQuery(e.target.value)}
							/>
						</label>
						<input type="submit" id="search-contacts-btn" />
					</form>
				</div>
				<Grid container spacing={3} className="main-grid">
					<Grid item md={6} className="call-person-form">
						<div className="box-shadow text-center group-list vertical_scrollbar">
							{querydata ?
								querydata.map(function (group, i) {
									return (
										<div className="group-call-bottom-content">
											<div className="box-shadow text-center member-form vertical_scrollbar"></div>
											<Grid container className="call-person-details">
												<Grid item md={3} className="group-person-img">
													<img src={Contact_img} />

												</Grid>
												<Grid item md={3} className="groupName">{group.circle_title}</Grid>



												<Grid item md={3} className="count">
													<span className="call_count">{group.circle_details_data.length}<img src={MultipleUser} onClick={() => showdetails(i)} /></span>
												</Grid>
												<Grid item md={3} className="call-button">
													<Button size="small" variant="outlined"  onClick={()=>doGroupCall(selectedgroup)} color="primary" > <TabImg_1 style={{ marginRight: "7px" }} />
														Call</Button>
												</Grid>

								
											</Grid>
										</div>)
								}
								) : ''}

						</div>
						<Grid >
							<Button className="primary" variant="text" style={{ marginTop: "4%" }}
								onClick={() => openModal()}><Icon_multipleuser />Create New Group</Button>
						</Grid>
					</Grid>

					{is_member ? (
						<Grid md={6} style={{ marginTop: "15px" }} className="call-person-tab-content-inner">

							<div className="box-shadow text-center member-form vertical_scrollbar">
								<Button className="back-button" onClick={() => backtoMemberDetails()}><img src={leftIcon} style={{}} />Back</Button>

							
								{
									(mycontacts.length > 0) ?
										mycontacts.map(function (contact, i) {
											return (
												<div className="call-person-bottom-content " key={'mycontacts-' + i}>
													<div className="call-person-details">
														<Grid md={4} className="call-person-img">
															<p className="single_profinitial">{getprofile(contact.contact_name, '')}</p>
														</Grid>
														<Grid md={4} className="call-person-inner-details">
															<div className="call-person-name">
																<p>{contact.contact_name}</p>
															</div>
															
														</Grid>
														<Grid md={4} className="">
															<a href="javascript:" onClick={() => doselectedmember(contact, i)} className={selectedmember.member.includes(contact._id) ? 'Icon_member_added' : 'Icon_member'}>

																<Icon_userplus className="Icon_Contact_video"
																	onClick={() => setisclicked(true)} />
															</a>
														</Grid>
													</div>
												</div>
											);
										})
										:
										(<div className="single_contact">
											<p className="nodata_found">No Data Found</p>

										</div>)
								}
								
							</div>
							<Button className="primary" variant="text"
									style={{ marginTop: "4%" }} onClick={() => addgroupdetails()}
								>Add to Group
								</Button>
						</Grid>
						

					) : ''}
					{is_grouped ? (
						<Grid item md={6} className="call-person-form">

							<div className="box-shadow text-center member-form  vertical_scrollbar">
								<button className="close1" onClick={() => setis_grouped(false)}>
									<Icon_closegrey />
								</button>

								<div className="call-person-bottom-content">
									<div className="group-details">
										<div className="group-member-img">
											<img src={Contact_img2} />
										</div>
										<div className="group-member-inner-details">
											<div className="member">
												<span>{selectedgroup.circle_title}</span>

												<Grid style={{ marginTop: "5px" }}><img src={MultipleUser} />   Group</Grid>
										
											</div>
										</div>
										<div className="two-buttons">
											<Button size="small" variant="outlined" color="primary" className="call-btn" onClick={()=>doGroupCall(selectedgroup)}> <TabImg_1 style={{ marginRight: "7px" }} />
												Call</Button>
											<Button size="small" variant="outlined" color="primary" className="member-btn" onClick={() => showmember()}
											>
												<img src={MemberIcon}
													className="Icon_member-btn" />

										
												Add Member</Button>
										</div>
										<div>
											<Grid container className="sections-created">
												<Grid item md={6} className="created-grid">
													<div className="created">Created</div>
													<div className="date">{selectedgroup.created_at}</div>
												</Grid>
												<Grid item md={6} className="group-language">
													<div>Preferred Language</div>
													<div className="date">English</div>
												</Grid>
											</Grid>


										</div>
										<div className="cell"></div>
										<Grid container style={{ marginTop: "4px" }}>
											<Grid item md={3} className="member">{selectedgroup.circle_details_data.length} Members</Grid>
											<div className="viewall">View All</div>
										</Grid>
										{selectedgroup.circle_details_data ?
											selectedgroup.circle_details_data.map(function (g, i) {
												return (
													<Grid container className="call-group-info">
														<Grid item md={3} className="group-person-img">
															<img src={Contact_img} />
														</Grid>
														<Grid item md={2} style={{ display: "grid" }}>
															<div style={{ marginTop: "10px" }}>{g.user_masters && g.user_masters[0] ? g.user_masters[0].first_name + g.user_masters[0].last_name : '-'}</div>
															<div style={{ marginBottom: "15px" }}>{g.user_masters && g.user_masters[0] ? g.user_masters[0].mobile_number : g.contact_number}</div>

														</Grid>
														<Grid item md={3}><img src={MemberDlt} className="member-delete" />
														</Grid>


													</Grid>)
											}) : ''}

									</div>
								</div>
								<Button variant="text" className="delete-btn">Delete Group</Button>


							</div>
						</Grid>) : ''}
				</Grid>
			</div>
			<Popup className="processingcall-modal language"

				modal
				open={addmemberpoup}
				closeOnDocumentClick
				onClose={closeModalMember}
			>
				<Grid md={12} style={{ marginTop: "15px" }} className="call-person-tab-content-inner">
					
					<div className="box-shadow text-center popup member-form vertical_scrollbar">


						
						{
							(mycontacts.length > 0) ?
								mycontacts.map(function (contact, i) {
									return (
										<div className="call-person-bottom-content" key={'mycontacts-' + i}>
											<div className="call-person-details vertical_scrollbar">
												<Grid md={4} className="call-person-img">
													<p className="single_profinitial">{getprofile(contact.contact_name, '')}</p>
												</Grid>
												<div className="call-person-inner-details">
													<Grid  className="call-person-name">
														<p>{contact.contact_name}</p>
													</Grid>
													
												</div>
												<Grid md={4} className="">
													<a href="javascript:" onClick={() => doselectedmember(contact, i)} className={selectedmember.member.includes(contact._id) ? 'Icon_member_added' : 'Icon_member'}>

														<Member_Icon className="Icon_Contact_video" />
													</a>

												</Grid>
											</div>
										</div>
									);
								})
								:
								(<div className="single_contact">
									<p className="nodata_found">No Data Found</p>

								</div>)
						}
					
					</div>
				</Grid>

				<div className="actions">
					<Button className="primary" onClick={() => addgroupdetails()} >Add</Button>
				</div>


			</Popup>
			<Popup className="processingcall-modal language"

				modal
				open={open}
				closeOnDocumentClick
				onClose={closeModal}
			>

				<div className="modal interpreter">
					<button className="close" onClick={closeModal}>
						<Icon_closegrey />
					</button>
					<div className="header"> Create Group </div>
					<div className="content special-svg">
						{' '}
						<div className="form-div">
							<label>Group Name</label>
							<TextField
								style={{ width: "80%" }}
								disableUnderline
								placeholder="Enter a Group Name"
								value={formdata.circle_title}
								name="circle_title"
								onChange={(e) => ongrpchange(e)}
							/>


						</div>
					</div>
					<div className="actions">
						<Button className="primary" onClick={() => createGroup()}>Add</Button>

					</div>
				</div>

			</Popup>
		</div>)
			: 
		(<ProccessingCall setNotconnected={setNotconnected} selectedmember={selectedmember} doselectedmember={doselectedmember} setProccessed={setProccessed} setSelected={setSelected} setisvideocallstarted={setisvideocallstarted} callAttendeeDetails={callAttendeeDetails}/>)} */}
		<div className="page-main">
			<div className="page-main-inner">
				<h2 style={{color:"gray"}}>Under Development</h2>
			</div>
		</div>
			</>
	)
	
}


export default MyGroups_content;