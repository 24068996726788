import React,{useEffect,useState} from "react";
import moment from 'moment';
import ProfilePic from "../Assets/home-user-profile.svg";
import HomeChange from "../Assets/home-profile-change.svg";
import MyConProfile from "../Assets/my-contact-profile.svg";
import MultipleUser from "../Assets/multiple-user.svg";
import HomeCallHistory1 from "../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../Assets/home-call-history-1.svg";
import style from '../Assets/css/style.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { MdLocationOn,MdPhone,MdStayCurrentPortrait,MdEmail ,MdOutlineLanguage} from "react-icons/md";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../Service/CommonService';
import {getprofile,getAuth} from '../Service/Helper';
import  Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";

const InterpreterHomepage = (props) => {
	const [is_loaded,setLoaded]=useState(false)
	const [is_grouploaded,setgroupLoaded]=useState(false)
	const [mycontacts,setmycontacts]=useState([])
	const [mycallHistory,setmycallHistory]=useState([])
	const authdata=getAuth()
	// const userid=8;
	const userid=authdata['_id'];
	const InterpreterId=77
	const contactDetails=[];
	useEffect(() => {
		getMissedCall();
	    getMyCall();
	}, []);
	const getMyCall= async () => {
		setLoaded(true);
	    var query = ""
	    const response = await getDataFromApi('checkCall/' + InterpreterId,query);
	    if(response.data.code && response.data.data!=null){
	    	setmycallHistory(response.data.data);
	    }
	    setLoaded(false);

	}
	const getMissedCall = async () => {
		setgroupLoaded(true);
	    var query = ""
	    const response = await getDataFromApi('missedCall/' + InterpreterId,query);
	    if(response.data.code && response.data.data!=null){
	    	setmycontacts(response.data.data);	
	    }
	    setgroupLoaded(false);
	}
	return(
		<div className="page-main">
			<div className="page-main-inner">
                <Box>
			       <Grid container spacing={3} className="main-grid home-grid">
			        
				       <Grid item md={5} className="my-contacts">
				            <div className="box-shadow radius-8px">
				              <div className="my-contacts-inner">
				                  <div className="blue-title">Incoming Calls</div>
				                    <div className="call-person-search-form">
										<form>
											<label>
												<img src={SearchIcon}/>
												<input
												type="text"
												name="search-contacts"
												className="search-contacts"
												id="search-contacts"
												placeholder="Search from Calls"
												/>
											</label>
											<input type="submit" id="search-contacts-btn"/>
										</form>
									</div> 
				                  { is_grouploaded ? <img className="loading_spinner" src={Spinner_150} />   : ''}
				                  <div className="mycontacts-detail vertical_scrollbar">
				                 
					                  		<div className="single_contact">
						                       {/* <p className="single_profinitial">ST</p> */}
											   <img src={Contact_img}  className="group-person-img"/>
						                       <span className="names">Swati Test</span>
						                       {/* <span className="call_count">0<img src={MultipleUser} /></span> */}
						                    </div>
                                            <div className="single_contact">
						                       {/* <p className="single_profinitial">ST</p> */}
											   <img src={Contact_img}  className="group-person-img"/>
						                       <span className="names">Swati Test</span>
						                       {/* <span className="call_count">0<img src={MultipleUser} /></span> */}
						                    </div>
                                            <div className="single_contact">
						                       {/* <p className="single_profinitial">ST</p> */}
											   <img src={Contact_img}  className="group-person-img"/>
						                       <span className="names">Swati Test</span>
						                       {/* <span className="call_count">0<img src={MultipleUser} /></span> */}
						                    </div>
                                            <div className="single_contact">
						                       {/* <p className="single_profinitial">ST</p> */}
											   <img src={Contact_img}  className="group-person-img"/>
						                       <span className="names">Swati Test</span>
						                       {/* <span className="call_count">0<img src={MultipleUser} /></span> */}
						                    </div>
					                  
				                 
				                  </div>
				              </div>
                            </div>
				       </Grid>
				       <Grid item md={7} className="call-history">
				          <div className="box-shadow radius-8px">
				             <div className="call-history-inner">
				                 <div className="blue-title">Missed Call</div>
				                 <div className="call-person-search-form">
										<form>
											<label>
												<img src={SearchIcon}/>
												<input
												type="text"
												name="search-contacts"
												className="search-contacts"
												id="search-contacts"
												placeholder="Search from Missed Call "
												/>
											</label>
											<input type="submit" id="search-contacts-btn"/>
										</form>
								  </div> 
				                 <div className="call-history-detail vertical_scrollbar">
				                 { is_loaded ? <img className="loading_spinner" src={Spinner_150} />   : ''}
				                 
								 {
				                 		(mycontacts.length>0) ?
					                 		mycontacts.map(function(call, i){
				                 	                  return (
					                 				<div className="single_callhistory" >
					                 					<Grid item md={2}>
														 <img src={Contact_img}  className="group-person-missed-call"/>
								                        </Grid>
								                        <Grid item md={8}>
								                        <span className="call_names_inter">
								                            
														    <span className="missedcall-names">{call.initiator_full_name}</span>
                                                            <span className="callhistory_inter">12.02.53</span>
								                        </span>
								                        </Grid>
								                        <Grid item md={2}>
								                        <span className="call_date">
                                                           {moment(call.call_initiate_at).format('HH:mm:ss')}
								                        </span>
								                        
								                        </Grid>
								                    </div>)
													
												})
											
											:
												(<div className="single_contact">
													 <p className="nodata_found">No Data Found</p>
													
												  </div>)
								}		
									
					                  	
				                 	
				                    
				                 </div>
				             </div>
				          </div>
				       </Grid>
				    </Grid>
				</Box>
			</div>
		</div>
	)
}

export default InterpreterHomepage;