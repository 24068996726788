import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import {ReactComponent as Icon_Contact_video} from "../../../Assets/call-a-person-video-call.svg";
import Spinner_150 from "../../../Assets/Spinner-1s-150px.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../../Service/CommonService';
import {getprofile} from '../../../Service/Helper';


const Contact_content = (props) => {
	const [is_Contactloaded,setContactLoaded]=useState(false)
	const [mycontacts,setmycontacts]=useState([])
	const userid=8;
	const contactDetails=[];
	const [query,setQuery]=useState("")
	useEffect(() => {
	    getMyContact();
	}, []);
	const getMyContact = async () => {
		setContactLoaded(true);
	    var query = "user_id="+userid+'&contactDetails=[]'
	    const response = await postDataFromApi('add/contactDetailsNew', query);
	    if(response.data.code && response.data.data!=null){
	    	setmycontacts(response.data.data);
	    }
	    setContactLoaded(false);
	}

	// console.log("query",query);
	const querydata=mycontacts.filter(mycontacts=>mycontacts.contact_name.toLowerCase().includes(query.toLowerCase()))
	console.log("querydata",querydata)


	return (
		<div className="call-person-tab-content">
			<div className="call-person-tab-content-inner">
				<div className="call-person-tab-header">
					<h3>Call A Person</h3>
				</div>
				<div className="call-person-search-form">
					<form>
						<label>
							<img src={SearchIcon}/>
							<input
							type="text"
							name="search-contacts"
							className="search-contacts"
							id="search-contacts"
							placeholder="Search from contacts, groups and calls"
							onChange={(e)=>setQuery(e.target.value)}
							/>
						</label>
						<input type="submit" id="search-contacts-btn"/>
					</form>
				</div>
				{/*<div className="call-person-bottom-title">
					<p>Select people for conference</p>
				</div>*/}
				{ is_Contactloaded ? <img className="loading_spinner" src={Spinner_150} />   : ''}
				{ 
					(querydata.length>0) ?
					querydata.map(function(contact, i){
				        return (
				        	<div className="call-person-bottom-content" key={'mycontacts-'+i}>
					<div className="call-person-details">
						<div className="call-person-img">
							<p className="single_profinitial">{getprofile(contact.contact_name,'')}</p>
						</div>
						<div className="call-person-inner-details">
							<div className="call-person-name">
								<p>{contact.contact_name}</p>
							</div>
							<div className="call-person-status">
								<p>Hey i am ready!</p>
							</div>
						</div>
						<div className="call-person-video-call">
							<a href="javascript:">
								<Icon_Contact_video className="Icon_Contact_video"/>
							</a>
						</div>
					</div>
				</div>
				);
				    })
				 :
					(<div className="single_contact">
						<p className="nodata_found">No Data Found</p>
						                      
					</div>)   
				}
				
			</div>
		</div>
	)
}

export default Contact_content;