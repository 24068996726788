import React,{useEffect,useState} from "react";
import Home from './../HomeComponent/Home';
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";
import {
	Routes,
	Route,
	NavLink,
	useNavigate ,
	HashRouter
} from "react-router-dom";
import Popup from 'reactjs-popup';
import Side_2 from "../../Assets/sidebar-2.svg";
import {ReactComponent as Icon_closegrey} from "../../Assets/close-grey.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import NotconnectedCall from './../ConferenceComponent/NotconnectedCall';
import ProccessingCall from './../ConferenceComponent/ProccessingCall';
import Interpreter_profile from "../../Assets/interpreter-profile.svg";
import Multiple_userwhite from "../../Assets/multiple-user-white.svg";
import Two_user from "../../Assets/two_user.svg";
import Two_userwhite from "../../Assets/two-user-white.svg";
import Connecting_interpreter_1 from "../../Assets/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../Assets/connecting-interpreter-2.svg";
import {ReactComponent as Iocn_Callback} from "../../Assets/callback.svg";
import {ReactComponent as Iocn_Cancel}  from "../../Assets/cancel.svg";
import {ReactComponent as Iocn_Notconnect_interpreter}  from "../../Assets/notconnect-interpreter-girl.svg";
import {ReactComponent as Icon_crossbordercancel } from "../../Assets/cross-border-cancel.svg";
import {ReactComponent as Iocn_Cancel_call}  from "../../Assets/cancel-call.svg";
import Connecting from "../../Assets/connecting.svg";
import {ReactComponent as Icon_time} from "../../Assets/time.svg";
import {getprofile,getAuth} from '../../Service/Helper';
import {getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails,CallSetupForSupport,custoclearinterval,BaseUrl,CallStatusUpdateData} from '../../Service/videoskp';
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import $ from 'jquery';

const GetInterpreter_page = () => {
	const [languages,setlanguages]=useState([])
	const [is_preccessed,setProccessed]=useState(false)
	const [is_notconnected,setNotconnected]=useState(false)
	const[languageId,setlanguageId]=useState("")
	const [formdata,setFormData]=useState({language:"1"})
	const authdata=getAuth()
	const userid=authdata['_id'];
    const[token,settoken]=useState([])
	const[MeetingData,setMeetingData]=useState([])
	const[MeetingId,setMeetingId]=useState('')
	const [CallResponseData,setCallResponseData] = useState({})
    const [intervalId, setIntervalId] = useState(false);
    const [isCallReceived,setisCallReceived] = useState(false)
    const [newIntervalId,setnewIntervalId]=useState('');
    const[isvideocallstarted,setisvideocallstarted]=useState(false);
    const [is_selected,setSelected]=useState(false)

    var processed=false;
    var singlenewIntervalId  ="";

	console.log(authdata)
	useEffect(() => {
	    getlanguages();
		chosedlanguage()

	}, []);
	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}

	const chosedlanguage= async (e) => {
		// setProccessed(true)
		console.log(formdata.language)
		// if(formdata.language){
		   gettoken();
		// }
	}
	const navigate = useNavigate();
	const [open, setOpen] = useState(true);
	const closeModal =()=>{
		navigate("/Home");
		setOpen(false);
	}
	function changedropdownvalue(e,type){
		console.log(type)
		console.log(e.target.value)
        if(e){
           var value=e.target.value
        }else{
            var value=""
        }
        setFormData((formData) => ({
            ...formData,
            [type]:value,
        }));
    }

    const gettoken = async () => {
		var query = ""
		const token = await getToken();
		console.log('get token',token)
		if(token && token!=null){
			settoken(token);
			getMeetingData(token);

		}
		
	}

	const getMeetingData = async (token) => {
		var query = ""
		const response = await createMeeting(token);
		console.log('response',response)
		if(response &&response!=null){
			setMeetingData(response)
			setMeetingId(response.meetingId)
			console.log(response.meetingId)
	          //startmeeting(response.meetingId)
	          settingCall(response)
			//   setProccessed(true)
	      }


	}

	const settingCall = async (data) => {
		console.log('data',data)
		var contactDetailsArray=[];

		contactDetailsArray=[{
	  		contact_number:authdata['mobile_number'],
	  		name:authdata['first_name'],

	  	}]

	  	var inputData = JSON.stringify({
	  		"user_id": userid,
	  		"call_type": "1",
	  		"language_id": formdata.language,
	  		"purpose_id": "1",
	  		"meetingDetails": data,
	  		"contactDetails": contactDetailsArray,
	  		"user_type": authdata['user_type'],
	  		"individual_user_type": authdata['user_category'],
	  		"is_same_gender": "0",
	  		"initiator_gender": authdata['gender'],
	  		"initiatorFullName": authdata['first_name'],
			"source":"web"
	  	})
		  

	  	console.log('inputData',inputData)

	  	/* call setup API */

	  	const callResponse = await CallSetupForSupport(inputData);
	  	setCallResponseData(callResponse)
		//   setProccessed(true)
	  	console.log('callResponse',callResponse)
		//console.log('callResponse',callResponse.data.meetingId)

		if(callResponse && callResponse.code){
			if(!intervalId){
				setTimeout(function () {
					if(!isCallReceived){
						CallStatusUpdateData(callResponse.data._id,'7','6')	
						clearInterval(singlenewIntervalId);
						setIntervalId(true);
					
						setNotconnected(true)
					}
				}, 30000);
				singlenewIntervalId=setInterval(function () {
					console.log('every 3 seconds')
					startTimer(callResponse)
				}, 3000);
				setnewIntervalId(singlenewIntervalId)
			}
			//clearInterval(singlenewIntervalId);
			/*if (intervalId) {
		      clearInterval(singlenewIntervalId);
		      
		      console.log("  cc clear")
		      
		  }*/


		}
	}
    
    const startTimer = async (data) => {
		/*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
		console.log('console.log(response)',response1)*/
		const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'],authdata['user_type'], token);
		console.log('startTimer',response)

		if (response && response.data !== "data not found") {
			console.log(newIntervalId);
			// response.data.status = 2
			if (response.data.status == 2) {
				if(!processed){
					processed=true;
					clearInterval(newIntervalId);
					setIntervalId(true);
					CallStatusUpdateData(data.data._id,'3','3')
					startmeeting(data.data.meetingId,data.data._id)
					setisCallReceived(true)
				}
			}
		}


	}	

	const startmeeting=async(meetingId,callSetupid)=>{
		localStorage.setItem('callSetupid', callSetupid)
		localStorage.setItem('startedTime', new Date())
	    //here call start meeting api before initiate meeting our own api will be call
	    const config = {
	    	name: authdata['first_name'],
	    	meetingId: meetingId,
	    	apiKey:sdkAPIKey,
	    	// joinScreen: {
	    	// 	visible: false,

	    	// },
	    	containerId: "meeting-container-id",
	    	micEnabled: true,
	    	webcamEnabled: true,
	    	participantCanToggleSelfWebcam: true,
	    	participantCanToggleSelfMic: true,

	    	chatEnabled: true,
	    	screenShareEnabled: true,
	    	canChangeLayout: true,
			redirectOnLeave:window.location.origin,
	    	// redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
	    	joinScreen: {
	    		visible: false,
	    	},
	    };

	    const meeting = new VideoSDKMeeting();
	    meeting.init(config);
	   	$(".full-screen").insertAfter(".page-main");
	   	
	    setisvideocallstarted(true)
	   	setNotconnected(true)

	}

	const CallStatusUpdate = async (buttonType)=>{

		clearInterval(newIntervalId);
		setIntervalId(true);

		if(buttonType=='cancel'){
			console.log('CallResponseData as',CallResponseData)
			const response = await CallStatusUpdateData(CallResponseData.data._id,'6','6');
			console.log('cancel',response)
			if(response && response.code){
				setNotconnected(false)
				setNotconnected(false)
				setProccessed(false)
				setSelected(false)
				clearInterval(singlenewIntervalId);
				navigate('/Home')

			}

		}else if(buttonType=='callback'){
			const response = await CallStatusUpdateData(CallResponseData.data._id,'5','6');
			console.log(response)
		}
	}

    function callbackrequest(){
		//clearInterval(newIntervalId);
		//setIntervalId(true);
		navigate('/Home')
	}
    
    

	return (
		<>
		{!is_notconnected ? 
			<>
			{!is_preccessed ?
			<>
			{/* <Home/> */}
			<div className="page-main" >
			<div className="page-main-inner conference">
			<div className="processing-call-tab-content">
			<div className="processing-call-tab-inner">
			<div className="calling-names-dash">
			<div className="inte-profile">
			<img src={Interpreter_profile} />
			</div>
			<div className="inte-profile-details">
			<span>{authdata.first_name+' '+authdata.last_name}</span>
			{/* <div>
			<span className="connecting_person">{authdata.length} <img src={Multiple_userwhite} /></span>
			// <span className="connecting_person_names"><img src={Two_userwhite} /> {authdata.first_name}{authdata.length > 1 ? '+' : ''}{authdata.length > 1 ?  authdata.length - 1 : ''}</span>
			</div> */}
			</div>
			</div>
			<div className="connect-interpreter">
			<img src={Connecting} />
			 {/* {authdata.first_name ? authdata.first_name : 'an interpreter'}  have commneted for line 347 */}
			<Typography>Connecting with Customer Support</Typography>
			<div className="connect-anim">
			<div className="connect-anim-1">
			<img src={Connecting_interpreter_1} className="Connecting_interpreter" />
			</div>
			<div className="connect-anim-2">
			<div className="dootedline" >
			<marquee direction="right" scrollamount="15">
			<i className="fa fa-caret-right"></i>
			</marquee>
			</div>
			<i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
			</div>
			<div className="connect-anim-3">
			<img src={Connecting_interpreter_2} className="Connecting_interpreter" />
			</div>
			</div>
			<div className="two_buttons">
			<Button className="secondary" variant="text"  onClick={()=>CallStatusUpdate('cancel')}><Iocn_Cancel /> Cancel</Button>
			<Popup className="processingcall-modal"
			trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
		}
		modal
		nested
		>
		{close => (
			<div className="modal interpreter">
			<div className="header"> We will connect you to an
			interpreter soon. </div>
			<div className="content special-svg">
			{' '}
			<Icon_time />
			</div>
			<div className="actions">
			<Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
			</div>
			</div>
			)}
			</Popup>

			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			</>
			:
				""

			}
			</>
			:
			 <>{isvideocallstarted ? <Box className="full-screen"><div id="meeting-container-id"></div></Box> :
			<div className="page-main" >
			<div className="page-main-inner conference">
			    <Box className="not_connect_call">
					<div className="processing-call-tab-content">
						<div className="processing-call-tab-inner">
						   <div className="calling-names-dash">
						      <div className="inte-profile">
		                         <img 
								//  src={Interpreter_profile} 
								src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg" 
								 />
						      </div>
						      <div className="inte-profile-details">
						         <span>Dashing Manbros</span>
						         <div>
						            <span className="connecting_person">{authdata.length} <img src={Multiple_userwhite} /></span>
						            <span className="connecting_person_names"><img src={Two_userwhite} /> {authdata.full_name}{authdata.length > 1 ? '+' : ''}{authdata.length > 1 ?  authdata.length - 1 : ''}</span>
						         </div>
						      </div>
						   </div>
						   <div className="connect-interpreter">
						      <Iocn_Cancel_call />
						      <Typography className="not_connect">Couldn’t connect with {authdata.full_name ?authdata.full_name : 'an interpreter'}</Typography>
						      <div className="svg_girl">
							      <Iocn_Notconnect_interpreter className="Connecting_interpreter" />
							      <Icon_crossbordercancel  className="cross"/>
						      </div>
						      <div className="two_buttons">
						       <Popup className="processingcall-modal"
							    trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
							       }
									    modal
									    nested
									  >
									    {close => (
									      <div className="modal interpreter">
									        <div className="header"> We will connect you to an
			                                    interpreter soon. </div>
									        <div className="content special-svg">
									          {' '}
									            <Icon_time />
									        </div>
									        <div className="actions">
									          <Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
									        </div>
									      </div>
									    )}
								</Popup>
						       <div className="dismiss" onClick={()=>callbackrequest()}>Dismiss</div>
						      </div>
						   </div>
						</div>
					</div>
				</Box>
			</div>
		</div>}</>
		}
		</>
	)
}


export default GetInterpreter_page;