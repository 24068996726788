import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Contact_video from "../../Assets/call-a-person-video-call.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import Call_tab from "./TabContent/Call";
import RecentCall_tab from "./TabContent/RecentCalls";
import Contact_tab from "./TabContent/Contact";
import MyGroups_tab from "./TabContent/MyGroup";

const TabsContents = (props) => {
	// if(props.tabvalue==0){
	// 	return ( <Call_tab/> )
	// }
	// else if(props.tabvalue==1){
	// 	return ( <RecentCall_tab/> )
	// }
	//  else if(props.tabvalue==2){
	if(props.tabvalue==0){
		return ( <Contact_tab/> )
	}
	else if(props.tabvalue==1){
		return ( <MyGroups_tab/> )
	}
		
}

export default TabsContents;