import React, { useState, useEffect } from 'react'
import { Typography, Paper, Grid, Box, TextField, Select, Radio, RadioGroup, FormControlLabel, Button, Modal, makeStyles, } from "@material-ui/core";
import { styled, alpha } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import EMDatePicker from "../Components/Datepicker"
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Input from '../../CommonComponent/Input';
import moment from "moment";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Icon from '@material-ui/core/Icon';
import background from "../../Assets/register.png";
//  import calenderIcon from "../../Assets/calender-icon.png"
import web from "../../Assets/Vector.png"
import male from "../../Assets/male.png"
import female from "../../Assets/female.png";
import "react-datepicker/dist/react-datepicker.css";
import leftIcon from "../../Assets/Left.png"
import { Formik, useFormik } from "formik";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { postDataFromApi, getDataFromApi } from '../../Service/CommonService'

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
//  import TextField from '@mui/material/TextField';

import Otp from "../LoginComponent/Otp"

import * as Yup from "yup";
import {
    HashRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    form: {
        justify: "center",
        alignItems: "center",
        direction: "row",
        display: "flex"
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        paddingHorizontal: 40,
    },
    inputContainer: {
        backgroundColor: '#FFFFFF',
        paddingHorizontal: 15,
        marginTop: 10,
        borderRadius: 8,
        borderColor: '#2760A3',
        borderWidth: 1,
        height: 60,
        width: '80%',
        marginBottom: "20px"
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: "1px solid rgb(42, 84, 250)",
        width: "400px",
        height: "59px",
        borderRadius: "5px",
        paddingTop: "3px",
        paddingLeft: "15px",
        paddingBottom: "2px"
    },
    inputLabel: {
        paddingTop: 5,
        fontSize: 13,
        color: '#5490D6',
        marginBottom: "2px",
    },
    input: {
        color: '#515151',
        fontWeight: 'bold',
        fontSize: 14,
        marginRight: 10,
        marginTop: -5,
        flex: 1,
        width: "397px"

    },
    image: {
        marginRight: "auto",
        marginLeft: "auto",

        height: '4%',
        width: '40%',

    },
    backgroundReg: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'

    },
    Transparent: {
        backgroundColor: "white",
        opacity: "0.8",
        width: "37%"
    },
    main: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",

    },
    dropdown: {
        border: "none !important"
    },
    register: {
        color: "white",
        width: "420px",
        borderRadius: "4px",
        height: "50px",
        backgroundColor: "#2760A3",
        marginTop: "4%"
    }

}));

const Theme = createTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: 'black',
                '&$error': {
                    color: '#db3131'
                },
            },
        },
    }
});



export default function RegisterFormCorporate() {
    const [value, setValue] = React.useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [isMale, setIsMale] = useState(true)
    const [isFemale, setIsFemale] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [isstate, setIsState] = useState('')
    const [state, setState] = useState([{ name: "select state", id: "1" }])
    const [isOpen, setIsOpen] = useState(false);
    const dateFormat = "yyyy/MM/dd"
    const phoneRegExp = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
    const options = [{ name: "india", id: "23" }]
    const [cities, setcities] = useState([{ name: "select City", id: "1" }])
    const classes = useStyles();
    const navigate = useNavigate();
    let validationSchema = Yup.object({
        first_name: Yup.string("Enter first name").trim().max(255)
            .required("first name is required"),
        last_name: Yup.string("Enter Last name").trim().max(255)
            .required("Last name is required"),
        mobile_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').max(10),

        email: Yup.string().email('Must be a valid email').trim().max(255)
            .required('Email is required'),

        city: Yup.string("select city").max(255)
            .required("City is required"),

        state: Yup.string("Select State").trim().max(255)
            .required("State is required"),

        dob: Yup.date("Enter Valid Date").max(new Date(), "You can't be born in the future!").nullable().typeError('Invalid Date')
    });

    useEffect(() => {

        poststates();

    }, []);

    const previousButton = () => {
        navigate('/register-form')
    }

    const poststates = async () => {
        var query = "model=StateMasterData"
        const response = await postDataFromApi('all/getAllMaster', query);
        console.log('states', response)
        setState(response.data.data);

    }
    const postcities = async (stateid) => {
        var query = "model=CityMasterData&state_id=" + stateid
        const response = await postDataFromApi('all/getAllMaster', query);

        setcities(response.data.data)
    }
    const multiselectchange = (e, type) => {
        console.log(e[0]['_id'])
        if (type == 'state') {
            postcities(e[0]['_id'])
            FormData.values.state = (e[0]['_id'])
        }
        else {
            FormData.values.city = (e[0]['_id'])
        }

    }
    const handleOpen = () => {
        setIsOpen(true); // Here we change state
    }

    const FormData = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            gender: [{
                id: 1,
                type: "Male",
                checked: false,
                src: male
            },
            {
                id: 2,
                type: "Female",
                checked: false,
                src: female
            },
            {
                id: 3,
                type: "Other",
                checked: false,
                src: undefined
            }
            ],
            dob: "",
            email: "",
            mobile_number: "",
            city_name: "",
            state_name: "",
            type: "",
        },
        validationSchema: validationSchema,

        onSubmit: (value) => {
            let data = {
                type: '2',//1=corporate 2=individual
                user_category: '1',//1=deaf 2=hearing
                first_name: value.first_name,
                last_name: value.last_name,
                dob: value.dob,
                email: value.email,
                mobile_number: value.mobile_number,
                gender: value.gender.find(gender => gender.checked == true).type,
                city: value.city,
                state: value.state
            }
            console.log('dataaa', data)
            const response = postDataFromApi('register-user', data);
            console.log('response', response);

            setIsOpen(!isOpen);
        }
    })









    // const multiselectchange=(e,type)=>{
    //   if (type=='state'){
    //     var query="model=CityMasterData&state_id="+e[0].id
    //      const response=postDataFromApi('all/getAllMaster', query);
    //      setcities(response.data)
    //     console.log(cities)
    //     FormData.values.state=e[0].id
    //   }
    //   else {
    //     FormData.values.city=e[0].id
    //   }

    // }


    return (
        <div className={classes.backgroundReg}>

            <Box style={{ padding: "10", paddingLeft: "2%" }}>

                <div style={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                }}>
                    <img src={web} alt="Logo" style={{ width: '100px', height: '70px' }} />
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold", fontSize: "22px", color: "#2760A3" }}>Sign</span>
                        <span style={{ display: "center", fontSize: "22px", color: "#FF9D00", fontWeight: "bold" }}>Able</span>
                    </div>
                </div>
                <Grid style={{ marginTop: "2%", paddingLeft: 15 }}>
                    <Typography style={{ fontSize: "17px", textAlign: "center", color: "#818181" }}>Register</Typography>
                </Grid>
                <form onSubmit={FormData.handleSubmit}>
                    {/* <Paper>  */}
                    <div className={classes.main}>
                        <div className={classes.Transparent}>
                            <Grid container spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <MuiThemeProvider theme={Theme}>
                                    <Grid item xs={12} >
                                        <Input

                                            label="First Name & Middle Name *"
                                            id="first_name"
                                            placeholder="First & Middle"
                                            value={FormData.values.first_name}
                                            onChange={FormData.handleChange}
                                            error={Boolean(FormData.touched.first_name && FormData.errors.first_name)}
                                            helperText={FormData.touched.first_name && FormData.errors.first_name}
                                            onBlur={FormData.handleBlur}
                                        />
                                    </Grid>
                                </MuiThemeProvider>
                                <Grid item xs={12}>
                                    <Input

                                        label="Last Name *"
                                        id="last_name"
                                        placeholder="Last Name"
                                        value={FormData.values.last_name}
                                        onChange={FormData.handleChange}
                                        error={Boolean(FormData.touched.last_name && FormData.errors.last_name)}
                                        helperText={FormData.touched.last_name && FormData.errors.last_name}
                                        onBlur={FormData.handleBlur}
                                    />
                                    {/* < EMDatePicker/> */}
                                </Grid>
                                <Grid item xs={12} >
                                    <div className={classes.inputContainer}>
                                        <div className={classes.row}>
                                            <Typography className={classes.inputLabel}>Date of Birth</Typography>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                <KeyboardDatePicker
                                                    selected={FormData.values.dob}
                                                    onChange={(value) => FormData.setFieldValue("dob", value)}
                                                    format={dateFormat}
                                                    // shrink={true}
                                                    disableUnderline
                                                    error={Boolean(FormData.touched.dob && FormData.errors.dob)}
                                                    helperText={FormData.touched.dob && FormData.errors.dob}
                                                    onBlur={FormData.handleBlur}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div className={classes.inputContainer}>
                                        <div className={classes.row}>
                                            <Typography className={classes.inputLabel}>Gender *</Typography>
                                            <div style={{ display: "flex", justifyContent: 10 }}>

                                                {FormData.values.gender.map((gender, idx) => (
                                                    <>
                                                        <Radio
                                                            name="gender"
                                                            id='gender'
                                                            style={{ marginTop: "-2.5%" }}
                                                            checked={gender.checked}
                                                            color="primary"
                                                            value={gender.type}
                                                            onClick={(e) => {
                                                                const values = [...FormData.values.gender];
                                                                values[idx].checked = e.target.checked;
                                                                FormData.setFieldValue("gender", values);
                                                            }}
                                                        />
                                                        {gender.src ? <img src={gender.src} style={{ width: "20px", height: "35px" }} />
                                                            : <Typography>Other</Typography>}

                                                    </>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div className={classes.inputContainer}>
                                        <div className={classes.row}>
                                            <Typography className={classes.inputLabel}>State *</Typography>
                                            {/* <InputLabel id="state">select state</InputLabel>    */}


                                            <Multiselect
                                                options={state && state.length > 0 ? state : options} // Options to display in the dropdown
                                                selectedValues={FormData.values.state_name}
                                                onSelect={(e) => multiselectchange(e, 'state')}
                                                // onRemove={this.onRemove} 
                                                displayValue="state_name"
                                                id='state'
                                                name='state'
                                                singleSelect='true'
                                                placeholder='Select State'
                                                error={Boolean(FormData.touched.state && FormData.errors.state)}
                                                helperText={FormData.touched.state && FormData.errors.state}
                                                onBlur={FormData.handleBlur}
                                                className={classes.multiselectContainer + ' selecteddropdown'}
                                                style={{ border: 'none !important' }}
                                            />

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div className={classes.inputContainer}>
                                        <div className={classes.row}>
                                            <Typography className={classes.inputLabel}>City*</Typography>
                                            <Multiselect
                                                options={cities && cities.length > 0 ? cities : options} // Options to display in the dropdown
                                                selectedValues={FormData.values.city}
                                                onSelect={(e) => multiselectchange(e, 'city')}
                                                displayValue="city_name"
                                                id='city'
                                                singleSelect='true'
                                                placeholder='Select City'

                                                error={Boolean(FormData.touched.city && FormData.errors.city)}
                                                helperText={FormData.touched.city && FormData.errors.city}
                                                className={classes.multiselectContainer + ' selecteddropdown'}
                                                onBlur={FormData.handleBlur}
                                            //  style={multiselectContainer: {border: 'none!important'}}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <Input
                                        required
                                        label="Email *"
                                        id="email"
                                        name='email'
                                        placeholder="yourmail@gmail.com"
                                        value={FormData.values.email}
                                        onChange={FormData.handleChange}
                                        error={Boolean(FormData.touched.email && FormData.errors.email)}
                                        helperText={FormData.touched.email && FormData.errors.email}
                                        onBlur={FormData.handleBlur}

                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Input
                                        required
                                        label="Phone"
                                        id="mobile_number"
                                        name='mobile_number'
                                        placeholder="91-"
                                        value={FormData.values.mobile_number}
                                        onChange={FormData.handleChange}

                                        error={Boolean(FormData.touched.mobile_number && FormData.errors.mobile_number)}
                                        helperText={FormData.touched.mobile_number && FormData.errors.mobile_number}
                                        onBlur={FormData.handleBlur}
                                    />
                                </Grid>


                                <Grid>
                                    <Button variant="filled" type="submit" style={{
                                        color: "white", width: "420px", borderRadius: "4px", height: "50px",
                                        backgroundColor: "#2760A3", marginTop: "4%"
                                    }}
                                    // onClick={() => {navigate('/Otp')}}
                                    >Register</Button>

                                </Grid>
                                {/* {
                    isOpen &&
                    <Otp/>
                  } */}

                                <Grid>
                                    <Button variant="outlined" style={{ marginTop: "16%", color: "#2760A3" }}
                                        onClick={() => { navigate('/register') }}
                                    >
                                        <div><img src={leftIcon} style={{}} /></div>Previous</Button>
                                </Grid>


                            </Grid>

                            {/* </Paper>  */}
                        </div>
                    </div>
                </form>


            </Box>
        </div>
    )
}
