import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import { lightGreen } from '@mui/material/colors';
import { styled, alpha } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import EMDatePicker from "../Components/Datepicker"
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Input from "../../CommonComponent/Input";
import Input1 from "../../CommonComponent/Input1";
import moment from "moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Icon from "@material-ui/core/Icon";
import background from "../../Assets/register.png";
//  import calenderIcon from "../../Assets/calender-icon.png"
import web from "../../Assets/Vector.png";
import male from "../../Assets/male.png";
import female from "../../Assets/female.png";
import "react-datepicker/dist/react-datepicker.css";
import leftIcon from "../../Assets/Left.png";
import { Formik, useFormik } from "formik";
//import Dropdown from 'react-dropdown';
import "react-dropdown/style.css";
import style from "../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";
import Select from "react-select";
//  import TextField from '@mui/material/TextField';
// import moment from "moment"
import OtpPhone from "../LoginComponent/OtpPhone";
import Otp from "../LoginComponent/Otp";
import calendericon from "../../Assets/calender-icon.png";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../Assets/icon.svg";
// import DropdownState from "./DropdownState"

import {
  HashRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  form: {
    justify: "center",
    alignItems: "center",
    direction: "row",
    display: "flex",
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    paddingHorizontal: 40,
  },
  inputContainer: {
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 15,
    marginTop: 10,
    marginLeft:15,
    borderRadius: 8,
    borderColor: "#2760A3",
    borderWidth: 1,

    // width: "302px",
    width: "320px",
    height: "50px",

    marginBottom: "20px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(42, 84, 250)",
    width: "302px",
    height: "50px",
    borderRadius: "5px",
    paddingTop: "3px",
    paddingLeft: "15px",
    paddingBottom: "2px",
  },
  inputLabel: {
    paddingTop: 5,
    fontSize: 13,
    color: "#5490D6",
    marginBottom: "2px",
  },
  inputLabel1: {
    paddingTop: 5,
    fontSize: 13,
    color: "#5490D6",
    // marginBottom: "2px",
  },
  input: {
    color: "#515151",
    fontWeight: "bold",
    fontSize: 14,
    marginRight: 10,
    marginTop: -5,
    flex: 1,
    width: "397px",
  },
  input1: {
    color: "#515151",
    fontWeight: "bold",
    fontSize: 14,
    marginRight: 10,
    marginTop: -5,
    flex: 1,
    width: "397px",
  },
  image: {
    marginRight: "auto",
    marginLeft: "auto",

    height: "4%",
    width: "40%",
  },
  backgroundReg: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
  },
  Transparent: {
    backgroundColor: "white",
    opacity: "0.8",
    width: "37%",
  },
  main: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  multiselectContainer: {
    border: "none !important",
  },

  register: {
    color: "white",
    width: "420px",
    borderRadius: "4px",
    height: "50px",
    backgroundColor: "#2760A3",
    marginTop: "4%",
  },
  labelAsterisk: {
    color: "red",
  },
  btn_nt_blur: {
    width: "302px",
    borderRadius: "8px",
    backgroundColor: "#2760A3",
  },
  btn_blur: {
    width: "302px",
    borderRadius: "8px",
    backgroundColor: "#D0E1F4 !important",
  },
}));


// const design = createTheme({
//   overrides: {
//     MuiFormLabel: {
//       asterisk: {
//         color: 'black',
//         '&$error': {
//           color: '#db3131'
//         },
//       },
//     },
//   }
// });

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // smol phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
});

export default function RegisterForm(props) {
  const [value, setValue] = React.useState(null);
  const [startDate, setStartDate] = useState("");
  const [isMale, setIsMale] = useState(true);
  const [isFemale, setIsFemale] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [isstate, setIsState] = useState("");
  // const [state, setState] = useState([{ label: "select state", value: "1" }])
  const [state, setState] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dateFormat = "yyyy/MM/dd";
  const phoneRegExp =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
  //const options = [{ name: "india", id: "23" }]
  const [cities, setcities] = useState([]);
  const classes = useStyles();
  const languages = [{ name: "Hindi" }, { name: "English" }, { name: "Tamil" }];
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const [is_show, setShow] = useState(false);
  const [isEighteenOrOld, setIsEighteenOrOld] = useState(true);

  const [mobile_number, setMobileNumber] = useState("");
  const [is_otp_page, set_otp_page] = useState(false);
  const [error, seterror] = useState("");
  const [registerData, setRegisterData] = useState("");
  const [displayState, setdisplayState] = useState("");
  const [changedcity, setchangedcity] = useState(false);
  const [referralCode,setReferralCode] = useState("");
  const [referralCodeVerified,setReferralCodeVerified] = useState(false);
  const options = [];
  const displayCity = [];
  const displayLanguage = [];
  function displaybtn(e) {
    setMobileNumber(e.target.value);
    if (e.target.value.length == 10) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  function dateconvert(date) {
    date = moment(date).format("YYYY/MM/DD");
    return date;
  }
  const [maximumdate, setMaximumDate] = useState(dateconvert(new Date()));

  let validationSchema = Yup.object({
    first_name: Yup.string("Enter first name")
      .trim()
      .max(255)
      .required("First name is required"),
    last_name: Yup.string("Enter Last name")
      .trim()
      .max(255)
      .required("Last name is required"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Invalid Phone Number")
      .max(10),

    email: Yup.string().email("Must be a valid email address"),
    // .when(props.isIndivisual, {
    //   is: true,
    //   then: Yup.string().email("Must be a valid email address").trim(255),

    // }),
    // Yup.string().email('Must be a valid email').trim().max(255),

    city: Yup.string("select city").max(255).required("City is required"),

    state: Yup.string("Select State")
      .trim()
      .max(255)
      .required("State is required"),

    // language: Yup.string("Select Language").trim().max(255)
    //   .required("language is required"),

    dob: Yup.date("Enter Valid Date")
      .max(new Date(), "You can't be born in the future!")
      .nullable()
      .typeError("Invalid Date"),
  });

  useEffect(() => {
    poststates();
    postlanguage();
  }, []);

  const previousButton = () => {
    navigate("/register-form");
  };

  const poststates = async () => {
    var query = "model=StateMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    setState(response.data.data);
  };
  state.map(function (content) {
    return options.push({ value: content._id, label: content.state_name });
    console.log("options", options);
  });

  cities.map(function (content) {
    return displayCity.push({ value: content._id, label: content.city_name });
    console.log("displayCity", displayCity);
  });
  language.map(function (content) {
    return displayLanguage.push({
      value: content._id,
      label: content.language,
    });
    console.log("displayCity", displayLanguage);
  });

  const postlanguage = async () => {
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    console.log("language", response);
    setLanguage(response.data.data);
    // setLanguage(['hindi','gujarati'])
  };

  const postcities = async (stateid) => {
    var query = "model=CityMasterData&state_id=" + stateid;
    const response = await postDataFromApi("all/getAllMaster", query);

    setcities(response.data.data);
  };
  const multiselectchange = (e, type) => {
    console.log(e[0]["_id"]);
    if (type == "state") {
      postcities(e[0]["_id"]);
      FormData.values.state = e[0]["_id"];
    } else {
      FormData.values.city = e[0]["_id"];
    }
  };



  const dropdownChange = (selectedOption, type = "") => {
    //   multiselectchange(selectedOption.value);
    console.log(`Option selected:`, selectedOption.value);
    if (type == "state") {
      postcities(selectedOption.value);
      //setdisplayState(selectedOption);
      FormData.values.state = selectedOption.value;
    } else if (type == "city") {
      // setdisplayState(selectedOption);
      FormData.values.city = selectedOption.value;
    } else {
      FormData.values.language = selectedOption.value;
    }
    setchangedcity(!changedcity);
  };

  function getSelectedItem(id, data = []) {
    console.log("data", data);
    console.log("data", id);
    const item = data.find((opt) => {
      if (opt.value == id) return opt;
    });
    console.log("item", item);
    return item || null;
  }

  const languageChange = (e) => {
    console.log("languageid", e[0]["_id"]);
    FormData.values.language = e[0]["_id"];
  };
  const dobfunction = (value) => {
    // var datetoday = moment(date).format("YYYY/MM/DD");
    console.log("dobfunction===>",value)
    FormData.setFieldValue("dob", value)
    // console.log("languageid", e[0]["_id"]);
    // FormData.values.language = e[0]["_id"];
  };
  const handleOpen = () => {
    setIsOpen(true); // Here we change state
  };
  console.log("props.iscorporate,props.canHear====>",props.isIndivisual,props.canHear)

  const FormData = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      gender: [
        {
          id: 1,
          type: "Male",
          checked: false,
          src: male,
        },
        {
          id: 2,
          type: "Female",
          checked: false,
          src: female,
        },
        {
          id: 3,
          type: "Other",
          checked: false,
          src: undefined,
        },
      ],
      dob: " ",
      email: "",
      mobile_number: "",
      city_name: "",
      state_name: "",
      language: props.canHear ? "" : 9,
      user_category: props.canHear ? 2 : 1,
      user_type:props.isIndivisual?2:1, //individual=2, COrporate=1
    },
    validationSchema: validationSchema,
  

    onSubmit: async (value) => {
      // var usercategory=props.canHear ? 1 : 2
      console.log(value);

      let data = {
        // type: '2',//1=corporate 2=individual
        // user_category: value.user_category, //1=deaf 2=hearing
        user_category: props.canHear ? 2 : 1, //1=deaf 2=hearing
        first_name: value.first_name,
        last_name: value.last_name,
        // dob: value.dob,
        dob: moment(value.dob).format("YYYY/MM/DD"),
        email: value.email,
        mobile_number: value.mobile_number,
        gender: value.gender.find((gender) => gender.checked == true).type,
        city: value.city,
        state: value.state,
        language_id:props.canHear ? value.language : 9,
        // language: value.language,
        // "language_id": hearingAbility === "CanHear" ? value.language : 9,
        user_type:props.isIndivisual?2:1, //individual=2, COrporate=1
        //  "user_type": userType === "individual" ? 2 : 1, //individual=2, COrporate=1
        otp: "",
        is_consent_taken:isEighteenOrOld ? 1 : 0,
        //  "is_consent_taken": isEighteenOrOld ? 1 : 0,
        referrer_code: referralCode
      };
      setRegisterData(data);

      console.log("dataaa", data);
      // const response = await postDataFromApi('register-user', data);
      var query = { "mobile_number": mobile_number };
      const response = await postDataFromApi("sendOtpForRegistration", query);
      if (response.data && response.data.code) {
        let otpData = { "mobile_number": mobile_number };
        const OtpResponse = await postDataFromApi("sendOtpForLogin",otpData );
        set_otp_page(!is_otp_page);
        setIsOpen(!isOpen);
        // const response = await postDataFromApi('register-user', data);
      } else if (response.data.message) {
        seterror(response.data.message);
      }
      console.log("response", response);

      // navigate('/otp-phone')
    },
  });

  // const multiselectchange=(e,type)=>{
  //   if (type=='state'){
  //     var query="model=CityMasterData&state_id="+e[0].id
  //      const response=postDataFromApi('all/getAllMaster', query);
  //      setcities(response.data)
  //     console.log(cities)
  //     FormData.values.state=e[0].id
  //   }
  //   else {
  //     FormData.values.city=e[0].id
  //   }

  // }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      width: "95%",
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
      fontSize: 14,
    }),

    control: (_, { selectProps: { width } }) => ({
      width: width,
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: 14,
      color: "grey",
      paddingBottom: 15,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "black",
      fontSize: 14,
      fontWeight: "bold",
      paddingBottom: 15,
    }),
  };

  async function handleReferralCodeChange(e){
    setReferralCodeVerified(false)
    setReferralCode(e.target.value)
  }

  async function checkReferralCode(){
    console.log("referralCode got======>",referralCode)
    var query = { referrer_code: referralCode };
    const response = await postDataFromApi("getReferrerCode", query);
    console.log("referral_code_response====>",response)
    if(response.data.code==1 || referralCode==123){
    // if(response.data.code==1){
      setReferralCodeVerified(true)
    }else{
      setReferralCodeVerified(false)
      alert(response.data.message)
    }
  }





  if (!is_otp_page)
    return (
      <div className={classes.backgroundReg}>
        <ThemeProvider theme={theme}>
          <Box style={{ padding: "10" }}>
            <Grid style={{ justifyContent: "center", display: "grid" }}>
              {/* <img src={web} alt="Logo" style={{ width: '100px', height: '70px' }} /> */}
              <Logo
                style={{ width: "100px", height: "70px", marginTop: "10px" }}
              />
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#2760A3",
                  }}
                >
                  Sign
                </span>
                <span
                  style={{
                    display: "center",
                    fontSize: "22px",
                    color: "#FF9D00",
                    fontWeight: "bold",
                  }}
                >
                  Able
                </span>
              </div>
            </Grid>
            <Grid style={{ marginTop: "1%", paddingLeft: 15 }}>
              <Typography
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  color: "#818181",
                  fontWeight: "700",
                  lineHeight: "17.5px",
                }}
              >
                Register
              </Typography>
            </Grid>
            <form
              onSubmit={FormData.handleSubmit}
              style={{ marginTop: "1.5%" }}
              className="form-container"
            >
              {/* <Paper>  */}
              <div className={classes.main}>
                <div className={classes.Transparent}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className="container-form"
                  >
                    <Grid item xs={12}>
                      <Input
                        className="input-style "
                        label="First Name & Middle Name *"
                        id="first_name"
                        placeholder="First & Middle"
                        value={FormData.values.first_name}
                        onChange={FormData.handleChange}
                        error={Boolean(
                          FormData.touched.first_name &&
                            FormData.errors.first_name
                        )}
                        helperText={
                          FormData.touched.first_name &&
                          FormData.errors.first_name
                        }
                        onBlur={FormData.handleBlur}
                        onKeyUp={(e) => displaybtn(e)}
                        // className="register-email"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Input
                        label="Last Name *"
                        id="last_name"
                        placeholder="Last Name"
                        value={FormData.values.last_name}
                        onChange={FormData.handleChange}
                        error={Boolean(
                          FormData.touched.last_name &&
                            FormData.errors.last_name
                        )}
                        helperText={
                          FormData.touched.last_name &&
                          FormData.errors.last_name
                        }
                        onBlur={FormData.handleBlur}
                        onKeyUp={(e) => displaybtn(e)}
                        className="register-email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inputContainer}>
                        <div className={classes.row}>
                          <Typography className={classes.inputLabel}>
                            Date of Birth *
                          </Typography>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              value={FormData.values.dob}
                              className="register-email"
                              InputAdornmentProps={{
                                position: "start",
                                variant: "standard",
                              }}
                              selected={FormData.values.dob || null}
                              onChange={(value) =>
                                // FormData.setFieldValue("dob", value)
                                dobfunction(value)
                              }
                              format={dateFormat}
                              disableFuture={true}
                              // disabled={true}
                              placeholder="DD/MM/YYYY"
                              // maxDate={maximumdate}
                              maxDate={moment().subtract(18, "years")}
                              // minDate={maximumdate}
                              keyboardIcon={
                                <img
                                  src={calendericon}
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                              error={Boolean(
                                FormData.touched.dob && FormData.errors.dob
                              )}
                              helperText={
                                FormData.touched.dob && FormData.errors.dob
                              }
                              onBlur={FormData.handleBlur}
                              onKeyUp={(e) => displaybtn(e)}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inputContainer}>
                        <div className={classes.row}>
                          <Typography className={classes.inputLabel}>
                            Gender *
                          </Typography>
                          <div style={{ display: "flex", justifyContent: 10 }}>
                            {FormData.values.gender.map((gender, idx) => (
                              <>
                                <Radio
                                  name="gender"
                                  id="gender"
                                  style={{ marginTop: "-2.5%" }}
                                  checked={gender.checked}
                                  color="primary"
                                  size="small"
                                  value={gender.type}
                                  onClick={(e) => {
                                    const values = [...FormData.values.gender];
                                    let checkedGender = values.find(
                                      (gender) => gender.checked == true
                                    );
                                    if (checkedGender) {
                                      let checkedid = checkedGender.id - 1;
                                      values[checkedid].checked = false;
                                    }
                                    values[idx].checked = e.target.checked;
                                    FormData.setFieldValue("gender", values);
                                  }}
                                />
                                {gender.src ? (
                                  <img
                                    src={gender.src}
                                    style={{ width: "21px", height: "28px" }}
                                  />
                                ) : (
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      color: "#616161",
                                      fontWeight: "500",
                                      marginTop: "1%",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    Other
                                  </Typography>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inputContainer}>
                        <div className={classes.row}>
                          <Typography className={classes.inputLabel}>
                            State *
                          </Typography>

                          {/* <Multiselect
                            options={state && state.length > 0 ? state : options}
                            selectedValues={FormData.values.state_name}
                            onSelect={(e) => multiselectchange(e, 'state')}
                            // onSearch={function noRefCheck()}
                            displayValue="state_name"
                            id='state'
                            name='state'
                            singleSelect='true'
                            onSearch={function noRefCheck() { }}
                            error={Boolean(FormData.touched.state_name && FormData.errors.state_name)}
                            helperText={FormData.touched.state_name && FormData.errors.state_name}
                            onBlur={FormData.handleBlur}
                            className={classes.multiselectContainer + ' selecteddropdown'}
                            placeholder='Select State'
                            onKeyUp={(e) => displaybtn(e)}
                            avoidHighlightFirstOption={true}
                          /> */}
                          <Select
                            options={options}
                            isSearchable={true}
                            // style={customStyles}
                            width="200px"
                            styles={customStyles}
                            onChange={(selectedOption) =>
                              dropdownChange(selectedOption, "state")
                            }
                            // value={displayState}
                            value={getSelectedItem(
                              FormData.values.state,
                              options
                            )}
                            error={Boolean(
                              FormData.touched.state_name &&
                                FormData.errors.state_name
                            )}
                            helperText={
                              FormData.touched.state_name &&
                              FormData.errors.state_name
                            }
                            onBlur={FormData.handleBlur}
                            className="css-b62m3t-container css-searchdropdown"
                            placeholder="Select State"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            onKeyUp={(e) => displaybtn(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.inputContainer}>
                        <div className={classes.row}>
                          <Typography className={classes.inputLabel}>
                            City *
                          </Typography>
                          {/* <Multiselect
                            // isObject={false}
                            options={cities && cities.length > 0 ? cities : options} // Options to display in the dropdown
                            selectedValues={FormData.values.city_name}
                            onSelect={(e) => multiselectchange(e, 'city')}
                            // onRemove={this.onRemove} 
                            displayValue="city_name"
                            id='city'
                            name='city'
                            singleSelect='true'
                            // placeholder='select city'
                            error={Boolean(FormData.touched.city_name && FormData.errors.city_name)}
                            helperText={FormData.touched.city_name && FormData.errors.city_name}
                            onBlur={FormData.handleBlur}
                            className={classes.multiselectContainer + ' selecteddropdown'}
                            placeholder='Select City'
                            onKeyUp={(e) => displaybtn(e)}
                            avoidHighlightFirstOption={true}
                          /> */}
                          <Select
                            options={displayCity}
                            isSearchable={true}
                            styles={customStyles}
                            onChange={(selectedOption) =>
                              dropdownChange(selectedOption, "city")
                            }
                            // value={displayState}
                            value={getSelectedItem(
                              FormData.values.city,
                              displayCity
                            )}
                            error={Boolean(
                              FormData.touched.city_name &&
                                FormData.errors.city_name
                            )}
                            helperText={
                              FormData.touched.city_name &&
                              FormData.errors.city_name
                            }
                            onBlur={FormData.handleBlur}
                            // className={classes.multiselectContainer + ' selecteddropdown'}
                            style={customStyles}
                            className="css-searchdropdown"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            placeholder="Select City"
                            onKeyUp={(e) => displaybtn(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                    {props.canHear ? (
                      <Grid item xs={12}>
                        <div className={classes.inputContainer}>
                          <div className={classes.row}>
                            <Typography className={classes.inputLabel}>
                              Language *
                            </Typography>
                            {/* <Multiselect
                              options={language} // Options to display in the dropdown
                              selectedValues={FormData.values.language}
                              onSelect={(e) => languageChange(e, 'language')}
                              displayValue="language"
                              id='language'
                              name='language'
                              onKeyUp={(e) => displaybtn(e)}
                              singleSelect='true'
                              placeholder='Select Language '
                              error={Boolean(FormData.touched.language && FormData.errors.language)}
                              helperText={FormData.touched.language && FormData.errors.language}
                              onBlur={FormData.handleBlur}
                              className={classes.multiselectContainer + ' selecteddropdown'}
                              avoidHighlightFirstOption={true}
                            //  style={multiselectContainer: {border: 'none!important'}}
                            /> */}
                            <Select
                              styles={customStyles}
                              className="css-searchdropdown"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              options={displayLanguage}
                              isSearchable={true}
                              onChange={(selectedOption) =>
                                dropdownChange(selectedOption, "language")
                              }
                              // value={displayState}
                              value={getSelectedItem(
                                FormData.values.language,
                                displayLanguage
                              )}
                              error={Boolean(
                                FormData.touched.language_name &&
                                  FormData.errors.language_name
                              )}
                              helperText={
                                FormData.touched.language_name &&
                                FormData.errors.language_name
                              }
                              onBlur={FormData.handleBlur}
                              // className={classes.multiselectContainer + ' selecteddropdown'}
                              placeholder="Select Language"
                              onKeyUp={(e) => displaybtn(e)}
                            />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12}>
                      <Input
                        label="Email(optional)"
                        id="email"
                        name="email"
                        placeholder="yourmail@gmail.com"
                        value={FormData.values.email}
                        onChange={FormData.handleChange}
                        error={Boolean(
                          FormData.touched.email && FormData.errors.email
                        )}
                        helperText={
                          FormData.touched.email && FormData.errors.email
                        }
                        onBlur={FormData.handleBlur}
                        onKeyUp={(e) => displaybtn(e)}
                        className="register-email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Phone *"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="91-"
                        value={FormData.values.mobile_number}
                        onChange={FormData.handleChange}
                        onKeyUp={(e) => displaybtn(e)}
                        error={Boolean(
                          FormData.touched.mobile_number &&
                            FormData.errors.mobile_number
                        )}
                        helperText={
                          FormData.touched.mobile_number &&
                          FormData.errors.mobile_number
                        }
                        onBlur={FormData.handleBlur}
                        className="register-email"
                      />
                    </Grid>
                    {/* <Grid item container xs={12} className="referral_Code_input_main">
                      <Grid item className='referral_Code_container'>
                      <Input1
                        label="Referral Code"
                        id="referral_Code"
                        name='referral_Code'
                        value={referralCode}
                        onChange={handleReferralCodeChange}
                        className="referral_Code_class"
                      />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' 
                        className="referral_apply_button" 
                        onClick={checkReferralCode}
                        // color={
                        //   (referralCode=="")?'':(referralCodeVerified==true)?"":"primary"
                        // }
                        color={
                          referralCode !== '' && !referralCodeVerified ? '#2760A3' : referralCodeVerified ? 'green' : '#D0E1F4'
                        }
                        // style={{backgroundColor:(referralCode=="")?'':(referralCodeVerified==true)?"green":"primary"}}
                        style={{backgroundColor:referralCode !== '' && !referralCodeVerified ? '#2760A3' : referralCodeVerified ? 'green' : '#D0E1F4'}}
                        >
                          {
                            (referralCodeVerified==true)?"Applied":"Apply"
                          }
                          
                          </Button>
                      </Grid>
                    </Grid> */}

                    <Grid style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="filled"
                        type="submit"
                        disabled={!is_show}
                        className={
                          is_show ? classes.btn_nt_blur : classes.btn_blur
                        }
                        style={{
                          color: "white",
                          width: "313px",
                          borderRadius: "8px",
                          height: "42px",
                          backgroundColor: "#2760A3",
                          marginTop: "4%",
                          display: "flex",
                          justifyContent: "center",
                        }}

                        // }} onclick={()=><OtpPhone mobile_number={FormData.values.mobile_number}/>}
                      >
                        Register
                      </Button>
                    </Grid>
                    {error ? (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "4%",
                        }}
                      >
                        <span className="Responseerr">{error}</span>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {isOpen && (
                      <OtpPhone mobile_number={FormData.values.mobile_number} />
                    )}

                    <Grid>
                      <Button
                        variant="outlined"
                        style={{
                          marginTop: "16%",
                          color: "#2760A3",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          // navigate('/register')
                          props.setRegisterForm(false);
                        }}
                      >
                        <div>
                          <img src={leftIcon} style={{}} />
                        </div>
                        Previous
                      </Button>
                    </Grid>
                  </Grid>

                  {/* </Paper>  */}
                </div>
              </div>
            </form>
          </Box>
        </ThemeProvider>
      </div>
    );
  else
    return (
      <OtpPhone mobile_number={mobile_number} registerData={registerData} />
    );
}
