import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import {ReactComponent as Icon_Contact_video} from "../../../Assets/call-a-person-video-call.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../../Service/CommonService';
import {ReactComponent as Icon_userplus} from "../../../Assets/user-plus.svg";

const MyGroups_content = (props) => {
	return (
		<div className="call-person-tab-content extra-shadow-bottom">
			<div className="call-person-tab-content-inner">
				<div className="call-person-tab-header">
					{/*<h3>Call A Person</h3>*/}
				</div>
				<div className="call-person-search-form">
					<form>
						<label>
							<img src={SearchIcon}/>
							<input
							type="text"
							name="search-contacts"
							className="search-contacts"
							id="search-contacts"
							placeholder="Search from contacts, groups and calls"
							/>
						</label>
						<input type="submit" id="search-contacts-btn"/>
					</form>
				</div>
				<div className="call-person-bottom-title">
					<p>Select people for conference</p>
				</div>
				<div className="call-person-bottom-content">
					<div className="call-person-details">
						<div className="call-person-img">
							<img src={Contact_img}/>
						</div>
						<div className="call-person-inner-details">
							<div className="call-person-name">
								<p>Alice Henery</p>
							</div>
							<div className="call-person-status">
								<p>Hey i am ready!</p>
							</div>
						</div>
						<div className="call-person-video-call">
							<a href="javascript:">
								<Icon_userplus className="Icon_Contact_video"/>
							</a>
						</div>
					</div>
				</div>
				<div className="call-person-bottom-content">
					<div className="call-person-details">
						<div className="call-person-img">
							<img src={Contact_img2}/>
						</div>
						<div className="call-person-inner-details">
							<div className="call-person-name">
								<p>Erika Barrite</p>
							</div>
							<div className="call-person-status">
								<p>Hey i am ready!</p>
							</div>
						</div>
						<div className="call-person-video-call">
							<a href="javascript:">
								<Icon_userplus className="Icon_Contact_video"/>
							</a>
						</div>
					</div>
				</div>
				<div className="call-person-bottom-content">
					<div className="call-person-details">
						<div className="call-person-img">
							<img src={Contact_img3}/>
						</div>
						<div className="call-person-inner-details">
							<div className="call-person-name">
								<p>Shirin Shaha</p>
							</div>
							<div className="call-person-status">
								<p>Hey i am ready!</p>
							</div>
						</div>
						<div className="call-person-video-call">
							<a href="javascript:">
								<Icon_userplus className="Icon_Contact_video"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyGroups_content;