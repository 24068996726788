import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import {getprofile,getAuth} from '../../Service/Helper';
import DataTable from 'react-data-table-component';

const CallHistorytable_page = () => {

	const [mycallHistory,setmycallHistory]=useState([])
	const [page, setPage] = useState(1);
	const [totalrecords, setTotalRecords] = useState(0);
	const [countPerPage, setcountPerPage] = useState(10);
	const [formdata,setFormData]=useState({search:""})
    //const countPerPage = 10;
	const authdata=getAuth()
	//const userid=8;
	const userid=authdata['_id'];

    const getMyCalltHistory = async () => {
		
	    var query = "user_id="+userid
	    // var skip=0
	    var skip=(page * countPerPage)-countPerPage
	    // const response = await postDataFromApi('callHistoryNew?page='+page+'&per_page='+countPerPage+'&delay=1', query);
	    const response = await postDataFromApi('callHistoryNew/'+skip+'/'+countPerPage, query);
	    console.log(response)
	    console.log(userid)
	    if(response.data.code && response.data.data!=null){
	    	setmycallHistory(response.data.data);
	    	// setTotalRecords(500);
	    	setTotalRecords(response.data.counter);
	    }
	    

	}
    
    const columns = [
	  {
	    name: 'Call initiate at',
	    selector: 'call_initiate_at',
	  },
	  {
	    name: 'Call type',
	    selector: 'call_type',
	  },
	  {
	    name: 'Full Name',
	    selector: 'initiator_full_name',
	  },
	  {
	    name: 'Gender',
	    selector: 'initiator_gender',
	  },
	  {
	    name: 'Initiator id',
	    selector: 'initiator_id',
	  },
	  {
	    name: 'Is call support',
	    selector: 'is_call_support',
	  },
	  {
	    name: 'Is missed call initiated',
	    selector: 'is_missed_call_initiated',
	  },
	  {
	    name: 'Is same gender',
	    selector: 'is_same_gender',
	  },
	  {
	    name: 'Language',
	    selector: 'language_id.language',
	  },
	  {
	    name: 'Meeting Id',
	    selector: 'meetingId',
	  },
	  {
	    name: 'Purpose id',
	    selector: 'purpose_id',
	  },
	  {
	    name: 'Status',
	    selector: 'status',
	  },
	];

	function formdatavaluechange(e){
        var value=e.target.value.trimStart()
        setFormData((formData) => ({
            ...formData,
            [e.target.name]:value,
        }));
        console.log(formdata.search)
    }

   /*const paginationComponentOptions = {
	  selectAllRowsItem: true,
	  selectAllRowsItemText: "ALL"
	};*/

	
	useEffect(() => {
	    
	    getMyCalltHistory();
	}, [page,countPerPage]);
  return (
  	<div className="page-main">
			<div className="page-main-inner">
			    <Grid container spacing={3} className="main-grid historygrid">
			    
			    <DataTable
			        title="Call History"
			        columns={columns}
			        data={mycallHistory}
			        highlightOnHover
			        pagination
			        paginationServer
			        paginationTotalRows={totalrecords}
			        paginationPerPage={countPerPage}
			        paginationRowsPerPageOptions={[10, 25, 50, 100]}
			        onChangePage={page => setPage(page)}
			        onChangeRowsPerPage={countPerPage => setcountPerPage(countPerPage)}
			    />
    
			    </Grid>
			</div>    
    </div>  
  )
}

export default CallHistorytable_page;