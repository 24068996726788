
import {mainURL}  from './CommonService'
import {getprofile,getAuth} from '../Service/Helper';

export const sdkAPIKey ="378c5971-9f73-494e-ba84-1d46661a5947"
export const BaseUrl=mainURL

const authdata=getAuth()
const userid=authdata['_id'];

export const getToken = async () => {
    try {
        const response = await fetch(mainURL + `/get-token`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const { token } = await response.json();
        return token;
    } catch (e) {
        console.log(e);
    }
};
export const createMeeting = async (token) => {
    try {
        const VIDEOSDK_API_ENDPOINT = BaseUrl + `/create-meeting`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
        };
        const response = await fetch(VIDEOSDK_API_ENDPOINT, options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};

export const callSetup = async (data) => {
    try {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: data,
        };
        const response = await fetch(BaseUrl + `/add/CallSetup`, options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};
export const CallSetupForSupport = async (data) => {
    try {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: data,
        };
        const response = await fetch(BaseUrl + `/add/CallSetupForSupport`, options)
        .then(async (result) => {
            const response = await result.json();
            return response;
        })
        .catch((error) => console.log("error", error));
        return response;
    } catch (e) {
        console.log(e);
    }
};

export const custoclearinterval=(id="")=>{
     clearInterval(id);
}
export const startTimerToGetCallDetails = async (callSetupId, userId, userName, userType, token) => {
    
            //console.log('callSetupId', callSetupId);
        /*const response  = fetch(BaseUrl + "/getcallDetails/" + callSetupId, {
                method: 'GET',
            }).then(response => {
               return response;
                console.log('response_calldetails', response);
                if (response.data !== "data not found") {
                    if (response.data.status === 2) {*/
                        // clearCurrentInterval()
                        // clearCurrentIntervalCall()
                        // CallStatusUpdateData(callSetupId, '3', '3')
                        /*navigation.navigate("MeetingInitializer", {
                            meetingConfig: {
                                call_setup_id: callSetupId,
                                userType,
                                userId,
                                userName,
                                meetingId: response.data.meetingId,
                                token,
                                videoOn,
                                micOn,
                            },
                        });*/
                    //}
               /* }
            }).catch(error => {
                console.log("error_calldetails", error)
            })
             return response;*/
    /*replaceCurrentIntervalCAll(interval_Id)*/

    try {
        const response = await fetch(BaseUrl + `/getcallDetails/` + callSetupId,  {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then(async (result) => {
            const res = await result.json();
            return res;
        })
        .catch((error) => console.log("error", error));;

        return response;
    } catch (e) {
        console.log(e);
    }
}

// var inputData = JSON.stringify({
//     "call_setup_id": callSetupId,
//     "user_type": "0",
//     "signable_user_type": "1",
//     "user_id": userId,
//     "is_call_end": "0",
//     "call_end_type": "0",
//     "call_status": '3',
//     "call_attendee_status": call_attendee_status,
//     "internet_bandwidth": networkBandwidth        })

 //1.params to be add in this file
//  call_second_used: callSecondsDuration,
//       is_buffer_used: isBufferedUsed ? 1 : 0,
//       used_buffer_second: bufferedSecondsDuration,
//       data.balanceSeconds;
// data.bufferSeconds;

// timer:call start to call end,
// balacne second ka timer:0 hota h toh cahalana h buffer timer
// buffer timer: by default o rakhna h

export const CallStatusUpdateData = async (call_setup_id, call_status, call_attendee_status,callSecondsDuration,isBufferedUsed,bufferedSecondsDuration) => {
    
        var inputData = JSON.stringify({
            "call_setup_id": call_setup_id,
            "user_type": "0",   
            "signable_user_type": "1",
            "user_id": userid,
            "is_call_end": "0",
            "call_end_type": "0",
            "call_status": call_status,
            "call_attendee_status": call_attendee_status,
            "call_second_used": callSecondsDuration,
            "is_buffer_used": isBufferedUsed ? 1 : 0,
            "used_buffer_second": bufferedSecondsDuration,
        })
        console.log('inputData_statusChanged', inputData)
       /* fetch(BaseUrl + "/add/CallStatusUpdate", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: inputData
        }).then(data => {
            return data.json();
        }).catch(error => {
            console.log(error)
        }).then(response => {
            console.log('callStatus_json', JSON.stringify(response));
            if (response.code === 1) {
                console.log('response', response.data)
            } else {
                console.log('error');
            }
        });*/

         try {
            const response = await fetch(BaseUrl + `/add/CallStatusUpdate`,  {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body:inputData,
            }).then(async (result) => {
               
                const res = await result.json();
                console.log('hii-->',res)
                if(call_status=="3" && call_attendee_status=="3"){
                    localStorage.setItem('startedTime', new Date())
                    localStorage.setItem('user_balance_call_seconds', res.user_balance_call_seconds)
                }
                return res;
            })
            .catch((error) => console.log("error", error));;

            return response;
        } catch (e) {
            console.log(e);
        }
}