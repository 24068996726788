import React from "react";
import { Typography, Paper, Grid, Box, TextField, Select,Radio, RadioGroup,FormControlLabel, Button, Modal,makeStyles, } from "@material-ui/core";
import {
	Routes,
	Route,
	NavLink,
	HashRouter
} from "react-router-dom";
import Popup from 'reactjs-popup';
import Side_1 from "../../Assets/sidebar-1.svg";
import Side_2 from "../../Assets/sidebar-2.svg";
import Side_3 from "../../Assets/sidebar-3.svg";
import Side_4 from "../../Assets/sidebar-4.svg";
import Side_5 from "../../Assets/sidebar-5.svg";
import Side_6 from "../../Assets/sidebar-6.svg";
import Style from "../../Assets/css/style.css";

const Main_sidebar= () =>{
	return (
		<div className="sidebar-main">
			<div className="sidebar-inner">
				<NavLink to="/CallaPerson">
							<div className="sidebar-block">
								<div className="side-img">
									<img src={Side_1}/>
								</div>
								<div className="side-txt-bar">
									<p className="side-title">Call A Person</p>
									<p className="side-txt">Talk to a person far away</p>
								</div>
							</div>
				</NavLink>
				<NavLink to="/GetInterpreter">
						<div className="sidebar-block">
							<div className="side-img">
								<img src={Side_2}/>
							</div>
							<div className="side-txt-bar">
								<p className="side-title">Get Interpreter</p>
								<p className="side-txt">Talk to a person near you</p>
							</div>
						</div>
				</NavLink>
				<NavLink to="/Conference">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_3}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Start A Conference</p>
							<p className="side-txt">Talk to more than one people</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/CallHistory">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_4}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">View Call History</p>
							<p className="side-txt">Check the calls you have made</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/ViewBalance">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_5}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">View Balance</p>
							<p className="side-txt">Check your account balance</p>
						</div>
					</div>
				</NavLink>
				<NavLink to="/CustomerSupport">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_6}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Get Customer Support</p>
							<p className="side-txt">Ask for assistance</p>
						</div>
					</div>
				</NavLink>
				{/* <NavLink to="/VideoCall">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_6}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Video Call</p>
						</div>
					</div>
				</NavLink> */}
				{/* <NavLink to="/Razorpay">
					<div className="sidebar-block">
						<div className="side-img">
							<img src={Side_6}/>
						</div>
						<div className="side-txt-bar">
							<p className="side-title">Razor pay</p>
						</div>
					</div>
				</NavLink> */}
			</div>
		</div>
	)
}

export default Main_sidebar;