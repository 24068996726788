import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ReactStars from "react-rating-stars-component";
// import ListItemButton from '@mui/material/ListItemButton'
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../Assets/call-a-person-contact-img3.svg";
import { ReactComponent as Clock } from "../Assets/clock 1.svg";
import Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_closegrey } from "../Assets/close-grey.svg";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../Service/CommonService";
import { getprofile, getAuth } from "../Service/Helper";
import moment from "moment";

import { TextField, Box } from "@material-ui/core";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import { CallStatusUpdateData } from "../Service/videoskp";
import swal from "sweetalert";

const InterpreterFeedbackform = (props) => {
  const authdata = getAuth();
  const userid = authdata["_id"];
  const callSetupid = localStorage.getItem("callSetupid");
  const navigate = useNavigate();
  const [is_Callloaded, setCallLoaded] = useState(false);
  const [is_show, setShow] = useState(false);

  const [onBreak, setonBreak] = useState(false);
  const [open, setOpen] = useState(false);
  const [mycontacts, setmycontacts] = useState([]);
  const [mybreak, setmybreak] = useState([]);
  const [call_setup_id, setcall_setup_id] = useState("");
  const [isBufferedUsed, setIsBufferedUsed] = useState("");
  const [formdata, setfromdata] = useState({
    rate_video_quality: "",
    rate_interpreter: "",
    feedback: "",
    user_id: userid,
    //user_type:authdata['user_type'],
    user_type: "0",
    call_setup_id: call_setup_id,
  });

  const ratingChanged = (type, newRating) => {
    setfromdata((formdata) => ({
      ...formdata,
      [type]: newRating,
    }));

    console.log(formdata);
  };

  // const startBufferMinutesTimer = (bufferedSec) => {
  // 	    const minutes = Math.floor(bufferedSec / 60);
  // 	    const seconds = Math.floor(bufferedSec - minutes * 60);
  // 	    var time = `${minutes < 10 ? `0${minutes}` : minutes}
  // 	             :${seconds < 10 ? `0${seconds}` : seconds}`;
  // 	    console.log('time', time)
  // 	    const interval_ID = setInterval(() => {
  // 	      var current_time = time;
  // 	      console.log('buffer_time', current_time)
  // 	      var minutes = 0;
  // 	      var seconds = 0;
  // 	      var timeParts = current_time.split(":");
  // 	      minutes = parseInt(timeParts[0]);
  // 	      seconds = parseInt(timeParts[1]);

  // 	      if (seconds === 0 && minutes === 0) {
  // 	        clearCurrentIntervalBuffer();
  // 	        // leave();
  // 	        CallStatusUpdateData();
  // 	      } else {
  // 	        seconds = seconds - 1;

  // 	        if (minutes === 0) {
  // 	          minutes = 0;
  // 	        } else if (seconds <= 0) {
  // 	          minutes = minutes - 1;
  // 	          seconds = 59;
  // 	        }
  // 	        if (minutes <= 0) {
  // 	          minutes = 0;
  // 	        }

  // 	        var displyMinutes = minutes.toString();
  // 	        var displaySeconds = seconds.toString();
  // 	        if (minutes < 10) {
  // 	          displyMinutes = "0" + minutes.toString();
  // 	        }
  // 	        if (seconds < 10) {
  // 	          displaySeconds = "0" + seconds.toString();
  // 	        }

  // 	        time = displyMinutes + ":" + displaySeconds;
  // 	      }
  // 	    }, 1000);

  // 	    replaceCurrentIntervalBuffer(interval_ID);
  // 	  }

  // const startBalanceMinutesTimer = (balanceSec) => {
  //     const minutes = Math.floor(balanceSec / 60);
  //     const seconds = Math.floor(balanceSec - minutes * 60);
  //     var time = `${minutes < 10 ? `0${minutes}` : minutes}
  //              :${seconds < 10 ? `0${seconds}` : seconds}`;
  //     console.log('balance_time', time)
  //     const interval_ID = setInterval(() => {
  //       var current_time = time;
  //       console.log('current_time', current_time)
  //       var minutes = 0;
  //       var seconds = 0;
  //       var timeParts = current_time.split(":");
  //       minutes = parseInt(timeParts[0]);
  //       seconds = parseInt(timeParts[1]);

  //       if (seconds === 0 && minutes === 0) {
  //         console.log('buffer munites started')
  //         clearCurrentIntervalBalance();
  //         setIsBufferedUsed(true)
  //         bufferStartedTime = new Date()
  //         startBufferMinutesTimer()
  //       } else {
  //         seconds = seconds - 1;

  //         if (minutes === 0) {
  //           minutes = 0;
  //         } else if (seconds <= 0) {
  //           minutes = minutes - 1;
  //           seconds = 59;
  //         }
  //         if (minutes <= 0) {
  //           minutes = 0;
  //         }

  //         var displyMinutes = minutes.toString();
  //         var displaySeconds = seconds.toString();
  //         if (minutes < 10) {
  //           displyMinutes = "0" + minutes.toString();
  //         }
  //         if (seconds < 10) {
  //           displaySeconds = "0" + seconds.toString();
  //         }

  //         time = displyMinutes + ":" + displaySeconds;
  //       }
  //     }, 1000);

  //     replaceCurrentIntervalBalance(interval_ID);
  //   }

  const updatestatus = async () => {
    var startedDate = localStorage.getItem("startedTime");
    var user_balance_call_seconds = localStorage.getItem(
      "user_balance_call_seconds"
    ); //10sec
    const calllStartDateTime = moment(startedDate);
    const endDate = moment(new Date(new Date()));
    console.log("starttime", calllStartDateTime);
    console.log("endtime", endDate);
    var isBufferedUsed = 0;
    var bufferedSecondsDuration = 0;

    var callSecondsDuration = endDate.diff(calllStartDateTime, "seconds"); //15sec
    console.log("callSecondsDuration", callSecondsDuration);
    if (callSecondsDuration > user_balance_call_seconds) {
      isBufferedUsed = 1;
      bufferedSecondsDuration = callSecondsDuration - user_balance_call_seconds;
    }
    // if (isBufferedUsed > ) {
    // 	// const startDate = moment(bufferstartedTime)
    // 	// bufferedSecondsDuration = endDate.diff(startDate, 'seconds')
    // }

    const response = await CallStatusUpdateData(
      callSetupid,
      "4",
      "6",
      callSecondsDuration,
      isBufferedUsed,
      bufferedSecondsDuration
    );
    console.log("response", response);
    if (response.code) {
      console.log("response", response.data._id);
      setcall_setup_id(response.data._id);
      localStorage.setItem("startedTime", "");
      localStorage.setItem("user_balance_call_seconds", "");
      setfromdata((formdata) => ({
        ...formdata,
        call_setup_id: response.data._id,
      }));
    }
  };
  useEffect(() => {
    updatestatus();

    window.history.replaceState(
      "InterpreterFeedbackform#",
      "",
      "/#/InterpreterFeedbackform"
    );
    localStorage.setItem("callSetupid", "");
  }, []);
  const handlechange = (e) => {
    setfromdata((formdata) => ({
      ...formdata,
      [e.target.name]: e.target.value,
    }));
  };
  const sendfeedback = async () => {
    var response = "";
    console.log("formdata", formdata);
    response = await postDataFromApi("add/callFeedbackReceived", formdata);
    if (response.data.code) {
      console.log(response.data.message);

      swal({
        // title: "client added!",
        text: response.data.message,
        icon: "success",
      }).then(function () {
        navigate("/home");
      });
    } else {
      swal({
        // title: "client added!",
        text: response.data.message,
        icon: "error",
      }).then(function () {
        // navigate("/home");
      });
    }
  };
  return (
    <div className="page-main">
      <div className="page-main-inner">
        <div className="feeback-title">Call With Interpreter Ended</div>

        <Paper
          elevation={1}
          className="call-person-details "
          style={{ margin: "2%" }}
        >
          <Grid
            container
            spacing={3}
            md={12}
            className="contact-info"
            style={{ margin: "2%" }}
          >
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "140px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Rate the Video Quality
                  </div>
                  <div
                    className="contact-info-detail"
                    style={{ marginTop: "13px" }}
                  >
                    <div className="feedback-start-div">
                      <ReactStars
                        count={5}
                        onChange={(e) => ratingChanged("rate_video_quality", e)}
                        size={30}
                        // color= "#92B8E5"
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "140px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Rate the Interpreter
                  </div>
                  <div
                    className="contact-info-detail"
                    style={{ marginTop: "13px" }}
                  >
                    <div className="feedback-start-div">
                      <img src={Contact_img2} style={{ width: "40px" }} />
                      <ReactStars
                        count={5}
                        onChange={(e) => ratingChanged("rate_interpreter", e)}
                        size={30}
                        //   color= "#92B8E5"
                        activeColor="#ffd700"
                      />

                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className="contact-info"
              style={{ height: "140px" }}
            >
              <div className="interpreter-feedback-inner">
                <div className="feedbackinfo-inner add-padding-bott">
                  <div className="feedback-inner-title">
                    Enter Comments(optional)
                  </div>
                  <div className="textbox" style={{ marginTop: "8px" }}>
                    <TextField
                      name="feedback"
                      style={{ width: "100%" }}
                      disableUnderline
                      variant="outlined"
                      className="textbox-style"
                      placeholder="Enter Text"
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid className="feeback-btn" md={4}>
              <Button
                className="primary"
                variant="text"
                style={{ marginTop: "4%" }}
                onClick={sendfeedback}
              >
                Submit{" "}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default InterpreterFeedbackform;
