import React, { useEffect, useState } from "react";
import moment from "moment";
import ProfilePic from "../../Assets/home-user-profile.svg";
import HomeChange from "../../Assets/home-profile-change.svg";
import MyConProfile from "../../Assets/my-contact-profile.svg";
import MultipleUser from "../../Assets/multiple-user.svg";
import HomeCallHistory1 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../../Assets/home-call-history-1.svg";
import style from "../../Assets/css/style.css";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import {
  MdLocationOn,
  MdPhone,
  MdStayCurrentPortrait,
  MdEmail,
  MdOutlineLanguage,
} from "react-icons/md";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
  helplineUrl,
} from "../../Service/CommonService";
import { getprofile, getAuth } from "../../Service/Helper";
import Spinner_150 from "../../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { BsQuestionLg } from "react-icons/bs";

const Home_page = (props) => {
  const [is_loaded, setLoaded] = useState(false);
  const [is_grouploaded, setgroupLoaded] = useState(false);
  const [mycontacts, setmycontacts] = useState([]);
  const [mycallHistory, setmycallHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [callHistoryQuery, setCallHistoryQuery] = useState("");
  const [helplineUrlsend, sethelplineUrlsend] = useState("");
  const authdata = getAuth();
  // const userid=8;
  const navigate = useNavigate();
  const userid = authdata["_id"];
  const contactDetails = [];
  useEffect(() => {
    getMyContact();
    getMyCalltHistory();
  }, []);
  useEffect(() => {
    var url = helplineUrl + "Signable/1/3";
    sethelplineUrlsend(url);
  }, []);

  // getcalllistforuser
  // const intervalID = setInterval(() => {
  // 	// console.log("is going on")
  // 	var inputData = JSON.stringify({
  // 		"userId": userId
  // 	})
  // 	//console.log('inputData', inputData)
  // 	fetch(BaseUrl + getCallListForUser, {
  // 		method: 'POST',
  // 		headers: {
  // 			Accept: 'application/json',
  // 			'Content-Type': 'application/json'
  // 		},
  // 		body: inputData
  // 	}).then(data => {
  // 		return data.json();
  // 	}).catch(error => {
  // 		console.log(error)
  // 	}).then(response => {
  // 		setIsLoading(false);
  // 		//console.log('calllist', JSON.stringify(response));
  // 		if (response.data !== null) {
  // 			if (response.data.status === 3) {
  // 				clearCurrentInterval()
  // 				navigation.navigate('AcceptRejectCallScreen', { data: response.data })
  // 			}
  // 		}
  // 	})
  // }, 3000);

  const getMyCalltHistory = async () => {
    setLoaded(true);
    var query = "user_id=" + userid;
    const response = await postDataFromApi("callHistoryNew", query);
    if (response.data.code && response.data.data != null) {
      setmycallHistory(response.data.data);
    }
    setLoaded(false);
  };

  const getMyContact = async () => {
    setgroupLoaded(true);
    var query = "user_id=" + userid + "&contactDetails=[]";
    const response = await postDataFromApi("add/contactDetailsNew", query);
    if (response.data.code && response.data.data != null) {
      setmycontacts(response.data.data);
    }
    setgroupLoaded(false);
  };
  if (
    localStorage.getItem("successfullyLoggedIn") != null &&
    localStorage.getItem("successfullyLoggedIn") == "Success"
  ) {
    swal({
      // title: "client added!",
      text: "Successfully Logged In",
      icon: "success",
    });
    window.localStorage.removeItem("successfullyLoggedIn");
  }
  const getInCommingCall = async () => {
    var query = "userId=" + userid;
    const response = await postDataFromApi("getcalllistforuser", query);
    try{

      if (response.data.code && response.data != null) {
        console.log("status", response.data.data.status);
        if (response.data.data.status === 3) {
          navigate("/IncomingCall");
        }
        // ,{state:{data:response.data.data}}
      }
    }
    catch{
      console.log("error")
    }

    
  };
  useEffect(() => {
    if (localStorage.getItem("callSetupid")) {
      navigate("/InterpreterFeedbackform");
    }
    const intervalCall = setInterval(() => {
      console.log("data", intervalCall);
      getInCommingCall();
    }, 3000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  //   console.log("query", query);
  const querydata = mycontacts.filter((mycontacts) =>
    mycontacts.contact_name.toLowerCase().includes(query.toLowerCase())
  );
  //   console.log("querydata", querydata);

  // console.log("callHistoryQuery",callHistoryQuery)
  const callhistory_querydata = mycallHistory.filter((mycallHistory) =>
    mycallHistory.initiator_full_name
      .toLowerCase()
      .includes(callHistoryQuery.toLowerCase())
  );
  // console.log("mycallHistory data ----->",mycallHistory[0][0].initiator_full_name)
  // const callhistory_querydata=mycallHistory
  // console.log("callhistory_querydata",callhistory_querydata)

  return (
    <div className="page-main">
      <div className="page-main-inner">
        <Box>
          <Grid container spacing={3} className="main-grid home-grid">
            {/*<Grid item md={4}  className="home-user-profile">
							<div className="box-shadow text-center radius-8px">
								<div className="home-user-profile-inner">
									<div className="home-user-profile-top-txt">
										<p className="blue-title">Profile</p>
									</div>
									<div className="home-user-profile-img">
									<p className="profile_intial">{getprofile(authdata.first_name,authdata.last_name)}</p>
										<img className="intsa_icon" src={HomeChange}/>
									</div>
									<div className="profile_details">
									   <span>Hello</span>
									   <span className="prof-user-name">{authdata.first_name+' '+authdata.last_name}</span>
									</div>
								</div>
							</div>
				       </Grid>
				       <Grid item md={8} className="contact-info">
							<div className="box-shadow radius-8px">
								<div className="contact-info-inner add-padding-bott">
									<div className="blue-title">Contact Info</div>
									<div className="contact-info-detail">
									   <div><MdLocationOn size="25px" color="#515151"/><span className="normal-gery-text">49, suitland Street,c.g square USA</span></div>
									   <div><MdPhone size="25px" color="#515151"/><span className="normal-gery-text">+1 999 9999 9999</span></div>
									   <div><MdStayCurrentPortrait size="25px" color="#515151"/><span className="normal-gery-text">{authdata.mobile_number}</span></div>
									   <div><MdEmail size="25px" color="#515151"/><span className="normal-gery-text">{authdata.email}</span></div>
									   <div><MdOutlineLanguage size="25px" color="#515151"/><span className="normal-gery-text">www.zylon.com</span></div>
									</div>
								</div>
							</div>
				       </Grid>*/}
            <Grid item md={4} className="my-contacts">
              <div className="box-shadow radius-8px">
                <div className="my-contacts-inner">
                  <div className="blue-title">My Contacts</div>
                  <div className="call-person-search-form">
                    <form>
                      <label>
                        <img src={SearchIcon} />
                        <input
                          type="text"
                          name="search-contacts"
                          className="search-contacts"
                          id="search-contacts"
                          placeholder="Search from Contacts"
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        />
                      </label>
                      <input type="submit" id="search-contacts-btn" />
                    </form>
                  </div>
                  {is_grouploaded ? (
                    <img className="loading_spinner" src={Spinner_150} />
                  ) : (
                    ""
                  )}
                  <div className="mycontacts-detail vertical_scrollbar">
                    <div
                      className=""
                      style={{ marginBottom: "0px", paddingBottom: "0px" }}
                    >
                      <p className="nodata_found">No Data Found</p>
                      <br />
                      {/* <p className="nodata_found">You can access contacts only on the mobile app</p> */}
                    </div>
                    <div
                      className=""
                      style={{
                        marginTop: "0px",
                        paddingTop: "0px",
                        width: "200px",
                        margin: "auto",
                      }}
                    >
                      {/* <p className="nodata_found">No Data Found</p><br/> */}
                      <p className="nodata_found">
                        You can access contacts only on the mobile app
                      </p>
                    </div>
                    {/* {querydata.length > 0 ? (
                      querydata.map(function (contact, i) {
                        var profilename = getprofile(contact.contact_name, "");
                        return (
                          <div className="single_contact">
                            <p className="single_profinitial">{profilename}</p>
                            <span className="names">
                              {contact.contact_name}
                            </span>
                            <span className="call_count">
                              0<img src={MultipleUser} />
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="single_contact">
                        <p className="nodata_found">No Data Found</p><br/>
                        <p className="nodata_found">You can access contacts only on the mobile app</p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={8} className="call-history">
              <div className="box-shadow radius-8px">
                <div className="call-history-inner">
                  <div className="blue-title">Call History</div>
                  <div className="call-person-search-form">
                    <form>
                      <label>
                        <img src={SearchIcon} />
                        <input
                          type="text"
                          name="search-contacts"
                          className="search-contacts"
                          id="search-contacts"
                          placeholder="Search from Call History"
                          onChange={(e) => {
                            setCallHistoryQuery(e.target.value);
                          }}
                        />
                      </label>
                      <input type="submit" id="search-contacts-btn" />
                    </form>
                  </div>
                  <div className="call-history-detail vertical_scrollbar">
                    {is_loaded ? (
                      <img className="loading_spinner" src={Spinner_150} />
                    ) : (
                      ""
                    )}

                    {callhistory_querydata.length > 0 ? (
                      callhistory_querydata.map(function (contact, i) {
                        var initiatornames = [];
                        return (
                          <div
                            className="single_callhistory"
                            key={"mycontacts-" + i}
                          >
                            <Grid item md={2}>
                              {/* <span className="imgaes">

									                            {
										                        	contact.callAttendeeDetailsData ? contact.callAttendeeDetailsData.map(function(person,i){

										                        		var profilename=getprofile(person.user_id.first_name,person.user_id.last_name)
										                        		if(person.user_id)
										                        		{
										                        			initiatornames.push(person.user_id.first_name)
										                        		}
										                        		if(i<3){
										                        			initiatornames.push()
										                        			return (<p className="profile_intial">{profilename} </p>)
										                        		}

										                        		}) : null
										                        }

									                        </span> */}
                              <p className="single_profinitial">
                                {getprofile(contact.initiator_full_name)}
                              </p>
                            </Grid>
                            <Grid item md={8}>
                              <span className="call_names_inter">
                                <span className="callhistory_names">
                                  {initiatornames.join(",")}
                                </span>
                                <span className="callhistory_inter">
                                  {" "}
                                  {contact.initiator_full_name}
                                </span>
                              </span>
                            </Grid>
                            <Grid item md={2}>
                              <span className="call_date">
                                {contact.call_initiate_at &&
                                  moment(contact.call_initiate_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                {/* contact.call_initiate_at < moment().format("YYYY-MM-DD") ? contact.call_initiate_at : moment.utc(contact.call_initiate_at).local().startOf('seconds').fromNow() */}
                              </span>
                            </Grid>
                          </div>
                        );
                      })
                    ) : (
                      <div className="single_contact">
                        <p className="nodata_found">No Data Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        {/* <Fab color="primary" aria-label="add" className="helpButton" href="https://signablehelpline.disctesting.in/signable/" target={"_blank"}>
            <BsQuestionLg size={25}/>
          </Fab> */}
        <Fab
          color="primary"
          aria-label="add"
          className="helpButton"
          href={helplineUrlsend}
          target={"_blank"}
        >
          <BsQuestionLg size={25} />
        </Fab>
      </div>
    </div>
  );
};

export default Home_page;
