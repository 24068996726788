import React, { useEffect, useState } from "react";
import moment from 'moment';
import ProfilePic from "../Assets/home-user-profile.svg";
import HomeChange from "../Assets/home-profile-change.svg";
import MyConProfile from "../Assets/my-contact-profile.svg";
import MultipleUser from "../Assets/multiple-user.svg";
import { Multiselect } from 'multiselect-react-dropdown';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import HomeCallHistory1 from "../Assets/home-call-history-1.svg";
import HomeCallHistory2 from "../Assets/home-call-history-1.svg";
import HomeCallHistory3 from "../Assets/home-call-history-1.svg";
import style from '../Assets/css/style.css'
import stylenew from '../Assets/css/substyle.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MdLocationOn, MdPhone, MdStayCurrentPortrait, MdEmail, MdOutlineLanguage } from "react-icons/md";
import { postDataFromApi, getDataFromApi, putDataFromApi, patchDataFromApi } from '../Service/CommonService';
import { getprofile, getAuth } from '../Service/Helper';
import Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import EditBtn from "../Assets/edit-btn.svg"
import Flag_Ind from "../Assets/call-a-person-ind-flag.svg";

const InterpreterProfileEditpage = (props) => {
    const [is_loaded, setLoaded] = useState(false)
    const [is_grouploaded, setgroupLoaded] = useState(false)
    const [mycontacts, setmycontacts] = useState([])
    const [mycallHistory, setmycallHistory] = useState([])
    const authdata = getAuth()
    // const userid=8;
    const userid = authdata['_id'];
    const contactDetails = [];
    const interpreterid=77

    const [formdata, setFormData] = useState({ first_name: "", last_name: "", dob: "", gender: "", mobile_number: "", email: "", language_id: "", state_name: "", city_name: "", user_type: "", user_category: "", role: "", signable_user_type: "" })
    const [value, setValue] = React.useState(null);
    const [languages, setlanguages] = useState([])
    const [states, setstates] = useState([])
    const [cities, setcities] = useState([])
    const [profile, setprofile] = useState([])
    const options = [{ name: "india", id: "23" }]
    const [stateoptions, setstateoptions] = useState([]);
    const [cityoptions, setcityoptions] = useState([]);
    const [langoptions, setlangoptions] = useState([]);
    const [selectedlanguages,setselectedlanguages]=useState([])
    const [languagelist,setlanguagelist]=useState([])
    const addlanguage=[]


    useEffect(() => {
        getlanguages();
        getstates();

        getprofile();
    }, []);

    const getprofile = async () => {
        var query = ""
        const response = await getDataFromApi('find/interpreterProfile/' + interpreterid, query);
        console.log('profile', response)
        if (response.data.code && response.data.data != null) {
            setprofile(response.data.data[0]);
            console.log(response.data.data[0].state._id)
            setFormData((formData) => ({
                ...formData,
                ['first_name']: response.data.data[0].first_name,
                ['last_name']: response.data.data[0].last_name,
             
                ['mobile_number']: response.data.data[0].mobile_number,
                ['email']: response.data.data[0].email,
               


                //['language_id']:response.data.data[0].language_id,
                
              ['addlanguage'] :response.data.data[0].user_details_data.map((lang,i)=>{
                lang.language_masters.map((le,k)=>{
                addlanguage.push({'label':le.language,'id':le._id})
                })
            }),
            
            }));
            getcities(response.data.data[0].state._id);
            setselectedlanguages(addlanguage)
            console.log('selected lang',addlanguage)
            setTimeout(function () {
                //  setis_loaded(true)
            }, 1000)
// var  temp =languages.filter(item=>item.name!==removeitem)
        }

    }
    const getlanguages = async () => {
        var query = "model=LanguageMasterData"
        const response = await postDataFromApi('all/getAllMaster', query);
        console.log('language', response)
        if (response.data.code && response.data.data != null) {
            setlanguages(response.data.data);
            var langopts = [];
            response.data.data.map((lang, i) => {
                var lg = [];
                lg['id'] = lang._id
                lg['label'] = lang.language
                langopts.push(lg)
            })
            setlangoptions(langopts)
            console.log('states data', response);
        }
    }
    const getstates = async () => {
        var query = "model=StateMasterData"
        const response = await postDataFromApi('all/getAllMaster', query);

        if (response.data.code && response.data.data != null) {
            setstates(response.data.data);
            var stateopts = [];
            response.data.data.map((state, i) => {
                var st = [];
                st['id'] = state._id
                st['label'] = state.state_name
                stateopts.push(st)
            })
            setstateoptions(stateopts)
            console.log('states data', response);
        }
    }
    const getcities = async (stateid) => {
        var query = "model=CityMasterData&state_id=" + stateid
        const response = await postDataFromApi('all/getAllMaster', query);
        console.log(response)
        if (response.data.code && response.data.data != null) {
            setcities(response.data.data);
            var cityopts = [];
            response.data.data.map((city, i) => {
                var ct = [];
                ct['id'] = city._id
                ct['label'] = city.city_name
                cityopts.push(ct)
            })
            setcityoptions(cityopts)
            console.log('city data', response);
        }
    }
    const multiselectchange = (e, type) => {
        console.log(e[0]['id'])
        if (type == 'state') {
            getcities(e[0]['id'])
            FormData.values.state = (e[0]['id'])
            setFormData((formData) => ({
                ...formData,
                ['state_name']: e[0]['id'],
                ['city_name']: '',

            }));
        }
        else {
            FormData.values.city = (e[0]['id'])
            setFormData((formData) => ({
                ...formData,
                ['city_name']: e[0]['id'],


            }));
        }

    }
    let validationSchema = Yup.object({
        state_name: Yup.string("select city").max(255)
            .required("City is required"),

        state_name: Yup.string("Select State").trim().max(255)
            .required("State is required"),


    });


    const FormData = useFormik({
        initialValues: {
            city_name: "",
            state_name: "",
            first_name: "",
            validationSchema: validationSchema,
            onSubmit: async (value) => {
                console.log('formdata', value)
            }
        }
    })

    function getSelectedItem(id, options) {
        console.log('iod', id)
        console.log('options', options)
        console.log('iod', id)
        const item = options.find((opt) => {


            if (opt.id == id)
                return opt;
        })
        console.log('item', item)
        return item || null;


    }

    const updateprofile = async (e) => {
        console.log('selected language',selectedlanguages)
         let addlanguage=[]
        selectedlanguages.map((l)=>
            
          
              addlanguage.push({language_id:l.id})
          
        )
        console.log('addedland', addlanguage)
        //http://localhost:8000/api/update/userProfile
        var newformdata = {
           
            // "modelName": "user_master",
            
               
                "mobile_number": formdata.mobile_number,
                "first_name": formdata.first_name,
                "last_name": formdata.last_name,
                "email": formdata.email,
               ["languageDetails"]: addlanguage,

           
           
        }
        var response = '';
        console.log('formdata', )
        response = await patchDataFromApi('/interpreterProfile/update/'+interpreterid, newformdata);
        if (response.data.code) {
            getprofile();
            console.log(response.data.message)
        } else {
            console.log(response.data.message)
        }
    }
    /*const handleSubmit = async (e) => {
           console.log('check',formdata)
    }*/
    useEffect(() => {
        getMyContact();
        getMyCalltHistory();
    }, []);
    const getMyCalltHistory = async () => {
        setLoaded(true);
        var query = "user_id=" + userid
        const response = await postDataFromApi('callHistoryNew', query);
        if (response.data.code && response.data.data != null) {
            setmycallHistory(response.data.data);
        }
        setLoaded(false);

    }
    const getMyContact = async () => {
        setgroupLoaded(true);
        var query = "user_id=" + userid + '&contactDetails=[]'
        const response = await postDataFromApi('add/contactDetailsNew', query);
        if (response.data.code && response.data.data != null) {
            setmycontacts(response.data.data);
        }
        setgroupLoaded(false);
    }
    return (
        <div className="page-main">
            <div className="page-main-inner">

                <Box>

                    <Grid container spacing={3} className="main-grid home-grid">
                        <Grid item md={4} className="home-user-profile" style={{height:"380px"}}>
                            <div className="box-shadow text-center radius-8px">
                                <div className="home-user-profile-inner">
                                    <div className="home-user-profile-top-txt">
                                        <p className="edit-title-profile">CHANGE PROFILE PICTURE</p>
                                    </div>
                                    <div className="home-user-profile-img">
                                        {/* <p className="profile_intial">{getprofile(authdata.first_name,authdata.last_name)}</p> */}
                                        <img src={Contact_img} className="profile_intial" />
                                        <img className="intsa_icon" src={HomeChange} />

                                    </div>
                                    <div className="profile_details">
                                        <span>Hello</span>
                                        <span className="prof-user-name">Arti Pandey</span>
                                    </div>
                                </div>
                            </div>
                        </Grid>



                        <Grid item md={6} className="edit-profile">
                            <div className="box-shadow text-center radius-8px" style={{ height: "100%" }}>
                               
                                <div className="call-person-form-main " >
                                    {/* <div className="call-person-form-main-inner"> */}
                                        <form className="profile_form" onSubmit={FormData.handleSubmit}>
                                            {/* <Grid container spacing={3} className="main-grid"> */}
                                                <Grid item md={12}>

                                                    <div className="form-div">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            name="firstname"
                                                            className="firstname"
                                                            id="firstname"
                                                            placeholder="Enter firstname"
                                                            value={formdata.first_name || ''}
                                                            disabled
                                                           
                                                        />
                                                    </div>
                                                    <div className="form-div">
                                                        <label>Last Name</label>
                                                        <input
                                                            type="text"
                                                            name="lastname"
                                                            className="lastname"
                                                            id="lastname"
                                                            placeholder="Enter lastname"
                                                            value={formdata.last_name || ''}
                                                            disabled
                                                           
                                                        />
                                                    </div>

                                                    <div className="form-div">
                                                        <label className="img-label">
                                                            <img src={Flag_Ind} />
                                                            <input
                                                                type="text"
                                                                name="phone-number"
                                                                className="phone-number"
                                                                id="phone-number"
                                                                placeholder="+91  Enter phone number"
                                                                value={formdata.mobile_number || ''}
                                                                disabled
                                                              
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className="form-div">
                                                        <label>Email</label>
                                                        <input
                                                            type="text"
                                                            name="Email"
                                                            className="Email"
                                                            id="Email"
                                                            placeholder="Enter Email"
                                                            value={formdata.email || ''}
                                                            disabled
                                                         
                                                        />
                                                    </div>
                                                    <div className="form-div">
                                                        <label>Language</label>
                                                        {/* <Multiselect
                                                            options={languages && languages.length > 0 ? langoptions : options}

                                                            onSelect={(e) => multiselectchange(e, 'language')}
                                                            defaultValue={getSelectedItem(formdata.language_id, langoptions)}
                                                            displayValue="label"
                                                            id='language'
                                                            name='language'
                                                            singleSelect='false'

                                                            error={Boolean(FormData.touched.language && FormData.errors.language)}
                                                            helperText={FormData.touched.language && FormData.errors.language}


                                                            placeholder='Select language'

                                                        /> */}
                                                        <Multiselect
                                                        showArrow
                                                        hidePlaceholder={true}
                                                        displayValue="label"
                                                        showCheckbox={true}
                                                        onSelect={(e) =>setselectedlanguages(e,'id')}
                                                    //   defaultValue={getSelectedItem(formdata.language, langoptions)}
                                                            
                                                            // id='language'
                                                            // name='language'
                                             
                                                    placeholder="Select Language"
                                                    onSearch={(event)=>{
                                                   
                                                }}
                                             
                                          options={langoptions}
                                          selectedValues={selectedlanguages}
                                           />

                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>

                                                   

                                                   

                                                </Grid>
                                            {/* </Grid> */}
                                         
                                        </form>
                                    </div>
                                </div>
                            {/* </div> */}
                        </Grid>





                    </Grid>
                    <Grid md={12} >
                        <Button className="primary" variant="text" style={{ marginTop: "4%" }} onClick={(e)=>updateprofile(e)}>Submit </Button>
                    </Grid>
                </Box>
            </div>
        </div>
    )
}

export default InterpreterProfileEditpage;