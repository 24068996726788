import React,{useEffect,useState,useRef} from "react";
import Popup from 'reactjs-popup';
import {
	Routes,
	Route,
	NavLink,
	HashRouter,
	useNavigate
} from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../Assets/conference-user.svg";
import Conference_user_remove from "../../Assets/conference-user-remove.svg";
import Two_user from "../../Assets/two_user.svg";
import Two_userwhite from "../../Assets/two-user-white.svg";
import Multiple_userwhite from "../../Assets/multiple-user-white.svg";
import Interpreter_profile from "../../Assets/interpreter-profile.svg";
import Connecting_interpreter_1 from "../../Assets/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../Assets/connecting-interpreter-2.svg";
import {ReactComponent as Iocn_Callback} from "../../Assets/callback.svg";
import {ReactComponent as Iocn_Cancel}  from "../../Assets/cancel.svg";
import Connecting from "../../Assets/connecting.svg";
import {ReactComponent as Icon_whitevideocall} from "../../Assets/white-video-call.svg";
import {ReactComponent as Icon_teluguicon} from "../../Assets/telugu-icon.svg";
import {ReactComponent as Icon_time} from "../../Assets/time.svg";
import {ReactComponent as Icon_closegrey} from "../../Assets/close-grey.svg";
import NotconnectedCall from './NotconnectedCall';
import {getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails,custoclearinterval,BaseUrl,CallStatusUpdateData} from '../../Service/videoskp';
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {getprofile,getAuth} from '../../Service/Helper';
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../Service/CommonService';
import swal from "sweetalert"
import $ from 'jquery';

const _interval ='';
const ProccessingCall = (props) => {
	const navigate =useNavigate()
	const [token,settoken]=useState([])
	const [MeetingData,setMeetingData]=useState([])
	const [MeetingId,setMeetingId]=useState('')
	const [is_notconnected,setNotconnected]=useState(false)
	const [selectedmember,setselectedmember]=useState({member:[],contactdetails:[]})
	const [is_selected,setSelected]=useState(false)
	const authdata=getAuth()
	const userid=authdata['_id'];
	const [intervalId, setIntervalId] = useState(false);
	const [isCallReceived,setisCallReceived] = useState(false)
	const [CallResponseData,setCallResponseData] = useState({})
	const [newIntervalId,setnewIntervalId]=useState('');
	const [Showlangpopup,setShowlangpopup] = useState(false);
	const [Showintavailbale,setShowintavailbale] = useState(false);
	const [formdata,setFormData]=useState({language:""})
	const [languages,setlanguages]=useState([])
	const [Showerror,setShowerror] = useState(false);
	const [seconds,setSeconds] = useState(1);
	const [isCallbackRequest, setisCallbackRequest] = useState('')
	const [globalvar, setGlobalvar] = useState(false)
    let iscallback=false

    var singlenewIntervalId ="";
	var processed=false;
	// console.log("authdata----->",authdata)

	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}
	//  const _interval = useRef(null);
	 
	const interval=''
     const intervalFun = () => _interval.current;
	const clearCurrentInterval = () => {
		console.log('clear',interval)
        // clearInterval(intervalFun());
        clearInterval(interval);
    };

	
    const replaceCurrentInterval = (newInterval) => {
        clearCurrentInterval();
        interval.current  = newInterval ;
    };

	// const intervalFun = setInterval(() => {
	// 	setSeconds(seconds + 1);
	//   }, 1000);
	//   console.log()
	// 			 // clearing interval
	//  clearInterval(intervalFun);
	
    
    const chosedlanguage= async (e) => {
		/*setProccessed(true)*/
		
        if( formdata.language=="" || formdata.language=='default'){
		   setShowerror(true)
		}
		if(formdata.language && formdata.language!='default'){
			console.log(formdata.language)
		    setShowlangpopup(false);
		    gettoken()
		}
	}
    
	const gettoken = async () => {
		var query = ""
		const token = await getToken();
		console.log('get token',token)
		if(token && token!=null){
			settoken(token);
			getMeetingData(token);
      }
	}
	const getMeetingData = async (token) => {
		var query = ""
		const response = await createMeeting(token);
		console.log('response',response)
		if(response &&response!=null){
			setMeetingData(response)
			setMeetingId(response.meetingId)
			console.log(response.meetingId)
	          //startmeeting(response.meetingId)
	          settingCall(response)
	      }


	  }
	  const settingCall = async (data) => {
           var contactDetailsArray=[];
	  	var contactdetails = props.selectedmember.contactdetails
	  	console.log("contactdetails",contactdetails)

	  	contactDetailsArray=[{
	  		contact_number:authdata['mobile_number'],
	  		name:authdata['first_name'],

	  	}]

	  	contactdetails.map((contactdetails,i)=>{
	  		var ct={
	  			contact_number:contactdetails.contact_number,
	  			name:contactdetails.contact_name,
	  		};
                // var ct=[];
                
             //    ct['contact_number']=contactdetails.contact_number
	            // ct['name']=contactdetails.contact_name
	            contactDetailsArray.push(ct)
	        })

	  	console.log('contactDetailsArray',contactDetailsArray)

	  	var inputData = JSON.stringify({
	  		"user_id": userid,
	  		"call_type": "1",
	  		"language_id": props.language_id ? props.language_id :(formdata.language ? formdata.language : authdata['language_id']),
	  		"purpose_id": "1",
	  		"meetingDetails": data,
	  		"contactDetails": contactDetailsArray,
	  		"user_type": authdata['user_type'],
	  		"individual_user_type": authdata['user_category'],
	  		// "is_same_gender": "0", // checked h toh 1 else 0
	  		"is_same_gender": (props.genderoption==true)?"1":"0",
	  		"initiator_gender": authdata['gender'],
	  		"initiatorFullName": authdata['first_name'],
			"source":"web"
	  	})

	  	console.log('inputData',inputData)

	  	/* call setup API */

	  	const callResponse = await callSetup(inputData);
	  	setCallResponseData(callResponse)
	  	console.log('callResponse',callResponse)
		//console.log('callResponse',callResponse.data.meetingId)
        //callResponse.code=2
		if(callResponse && callResponse.code==1){
            if(!intervalId){
				var calltimeout=setTimeout(function () {
					if(!isCallReceived && !processed){
						CallStatusUpdateData(callResponse.data._id,'7','6')	
						//yaha pe clear interval ka function call krna h
						//clearCurrentInterval()
						//clearInterval(interval);
						clearCurrentInterval()
						//clearInterval(singlenewIntervalId);
						setIntervalId(true);
						props.setNotconnected(true)
						// setNotconnected(true)
					}
				}, 45000);
			setisCallbackRequest(calltimeout)
			 startTimer(callResponse)
			
			}

			//clearInterval(singlenewIntervalId);
			/*if (intervalId) {
		      clearInterval(singlenewIntervalId);
		      
		      console.log("  cc clear")
		      
		  }*/
          

		}
		else if(callResponse.code == 2){
			// alert(props.language_id)
			// if(props.language_id!=""){
			// 	// setShowintavailbale(false)
			// 	setFormData((formData) => ({
			// 			...formData,
			// 			['language']:props.language_id,
			// 		}));
			// 	setShowlangpopup(false);
		    // 	// gettoken()
			// }
			// else{
				// setShowlangpopup(true)
				// setShowintavailbale(false)
				// if(callResponse.data.language_id && callResponse.data.language_id!=0){
				// 	setFormData((formData) => ({
				// 		...formData,
				// 		['language']:callResponse.data.language_id,
				// 	}));
				// }

			// }
			setShowlangpopup(true)
			setShowintavailbale(false)
			if(callResponse.data.language_id && callResponse.data.language_id!=0){
				 setFormData((formData) => ({
				  ...formData,
				  ['language']:callResponse.data.language_id,
			  }));
			}
		}
		else if(callResponse.code == 3){
			swal({
					text: "No Interpreter available",
					icon: "error",
				  }).then(function () {
					navigate("/home");
				  });
                // setShowintavailbale(true)
                setShowlangpopup(false)

		}
		else{
			//alert(callResponse.message)
			// swal({
			// text: callResponse.message,
			// }).then(function () {
			// 	navigate('/home')
			// });
			//clearInterval(singlenewIntervalId);
			//clearCurrentInterval()	
			//clearInterval(interval);
			if (callResponse.message === "User Not found") {
				// clearInterval(singlenewIntervalId);
				clearCurrentInterval()
				swal({
				  text: "Your are blocked. Please contact admin team.",
				  icon: "error",
				}).then(function () {
				  navigate("/home");
				});
			  } else {
				// clearInterval(singlenewIntervalId);
				clearCurrentInterval()
				swal({
				  text: callResponse.message,
				  icon: "error",
				}).then(function () {
				  navigate("/home");
				});
			  }
			// clearCurrentInterval()
		}
		
	}	
	
	// useEffect(() => {
		const startTimer =(data) => {
			
			var count=0
			const interval = setInterval(async () =>  {
				const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'],authdata['user_type'], token);
				// console.log('startTimer response',response)
				count+=2
				if (response && response.data !== "data not found") {
				
					console.log("newIntervalId",newIntervalId);
					console.log("response.data.status",response.data.status);
					// response.data.status = 2
					if (response.data.status == 2) {
						if(!processed){
							processed=true;
							setisCallReceived(true)
							//clearInterval(newIntervalId);
							setIntervalId(true);
		                   
							//when call start 
							CallStatusUpdateData(data.data._id,'3','3')
		
							startmeeting(data.data.meetingId,data.data._id)
						    clearCurrentInterval()
							console.log('clear.....',clearCurrentInterval())
							// console.log("data.data._id response.data.status == 2",data.data._id)
							
						}
					}
					else if (response.data.status === 1) {
						if (count === 16 || count === 30 || count === 46) {
							checkIsCallConnected(data.data._id)
							// console.log("data.data._id response.data.status == 1",data.data._id)
						}
					}
				}
				// console.log('interval',interval)
				//  clearInterval(interval)
				// clearCurrentInterval()
				}, 2000);
				//replace interval
				replaceCurrentInterval(interval);
				
				
			}	
	// }, [])

	
// interpreter join code api
	const checkIsCallConnected = async (callSetupid) => {
        console.log('checkIsCallConnected', callSetupid);
	  
		const response = await getDataFromApi('sendNotificationRequestForInterpreterJoin/'+callSetupid);
            if (response && response.data.code === 0) {
				swal({
					text: "Sorry, There is no interpreter available .",
					icon: "success",
				}).then(function () {
					navigate('/home')
				});
			
		
		
		//clearInterval(interval);
		clearCurrentInterval()
		//clearInterval(singlenewIntervalId);
                
            }
			
        // })
    }
	const startmeeting=async(meetingId,callSetupid)=>{
		localStorage.setItem('callSetupid', callSetupid)
		localStorage.setItem('startedTime', new Date())
	    //here call start meeting api before initiate meeting our own api will be call
	    const config = {
	    	name: authdata['first_name'],
	    	meetingId: meetingId,
	    	apiKey:sdkAPIKey,
	    	// joinScreen: {
	    	// 	visible: false,

	    	// },
	    	containerId: "meeting-container-id",
	    	micEnabled: true,
	    	webcamEnabled: true,
	    	participantCanToggleSelfWebcam: true,
	    	participantCanToggleSelfMic: true,

	    	chatEnabled: true,
	    	screenShareEnabled: true,
	    	canChangeLayout: true,
			redirectOnLeave:window.location.origin,
	    //	redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
	    	joinScreen: {
	    		visible: false,
	    	},
	    };

	    const meeting = new VideoSDKMeeting();
	    meeting.init(config);
	   	$(".full-screen").insertAfter(".page-main");
	   	console.log(props.setisvideocallstarted)
	    props.setisvideocallstarted(true)
	   	setNotconnected(true)

	}
     const Languagecancel=()=>{
		navigate('/Home')
	 }
	const CallStatusUpdate = async (buttonType)=>{

		clearInterval(newIntervalId);
		setIntervalId(true);

		// console.log("newIntervalId",newIntervalId)

		if(buttonType=='cancel'){
			console.log('CallResponseData as',CallResponseData)
			const response = await CallStatusUpdateData(CallResponseData.data._id,'6','6');
			console.log('cancel',response)
			if(response && response.code){
				props.setNotconnected(false)
				setNotconnected(false)
				props.setProccessed(false)
				props.setSelected(false)
			//	clearInterval(singlenewIntervalId);
			clearInterval(interval);
			// global.clearTimeout(isCallbackRequest);
			setGlobalvar(true)
			window.location.reload(true)
			// clearTimeout(interval);
			// console.log("in when pressed cancel")
			

			}
        }else if(buttonType=='callback'){
			const response = await CallStatusUpdateData(CallResponseData.data._id,'5','6');
			console.log(response)
		}
	}
	useEffect(() => {
		gettoken();
		getlanguages();
		// checkIsCallConnected();
	    /*document.body.className = 'videocall';
	    return () => { document.body.className = ''; }*/
	    
	}, []);
	useEffect(() => {
		global.clearTimeout(isCallbackRequest);
	}, [setGlobalvar]);
	


	const callbackrequest=async()=>{
		global.clearTimeout(isCallbackRequest);
		const response = await CallStatusUpdateData(CallResponseData.data._id, '5', '6');
	    navigate('/Home')
		//clearInterval(newIntervalId);
		//setIntervalId(true);
	}

	const doselectedmember=(contact,index,page="")=>{
    	console.log('contact',contact)
    	
		let selected=selectedmember.member
		let singlecontactdetails=selectedmember.contactdetails
		if(selected.includes(contact._id)){
			const i = selected.indexOf(contact._id);
			if (i > -1) {
				selected.splice(i, 1);
				singlecontactdetails.splice(i,1)
			}
		}
		else{
			selected.push(contact._id)
			singlecontactdetails.push(contact)
		}
		console.log(selected)
		if(page == 'ViewSelectedTabs'){

			if(selected.length==0){
				setSelected(false)
			}
		}
		setselectedmember((formData) => ({
            ...formData,
			member:selected,
			contactdetails:singlecontactdetails
        }));

		console.log('selectedmember',selectedmember)

	}
	const closeModal =()=>{
		setShowlangpopup(false);
		setShowintavailbale(false)
	}
	function changedropdownvalue(e,type){
		setShowerror(false)
		console.log(type)
		console.log(e.target.value)
        if(e){
           var value=e.target.value
        }else{
            var value=""
        }
        setFormData((formData) => ({
            ...formData,
            [type]:value,
        }));
    }

	if(!intervalId && !props.isvideocallstarted){
		return (
			<div className="page-main">
				
				<div className="page-main-inner conference">
					<div className="processing-call-tab-content">
						<div className="processing-call-tab-inner">
						<div className="calling-names-dash">
							<div className="inte-profile">
							   <img 
							//    src={Interpreter_profile} 
							src="https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg" 
							   />
							</div>
							<div className="inte-profile-details">
							    <span>{authdata.first_name+" "+authdata.last_name}</span>
							    <div>
						           <span className="connecting_person">{props.selectedmember.member.length} <img src={Multiple_userwhite} /></span>
						           <span className="connecting_person_names"><img src={Two_userwhite} /> {props.selectedmember.contactdetails[0].contact_name}{props.selectedmember.member.length > 1 ? '+' : ''}{props.selectedmember.member.length > 1 ?  props.selectedmember.member.length - 1 : ''}</span>
						        </div>
						    </div>
						</div>
						<div className="connect-interpreter">
						    <img src={Connecting} />
						    <Typography>Connecting with {props.selectedmember.contactdetails[0].contact_name ? props.selectedmember.contactdetails[0].contact_name : 'an interpreter'}</Typography>
						    <div className="connect-anim">
						        <div className="connect-anim-1">
						            <img src={Connecting_interpreter_1} className="Connecting_interpreter" />
						        </div>
						        <div className="connect-anim-2">
						            <div className="dootedline" >
						                <marquee direction="right" scrollamount="15">
						                    <i className="fa fa-caret-right"></i>
						                </marquee>
						            </div>
						            <i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
						        </div>
						        <div className="connect-anim-3">
						            <img src={Connecting_interpreter_2} className="Connecting_interpreter" />
						        </div>
						    </div>
						<div className="two_buttons">
						<Button className="secondary" variant="text" onClick={()=>CallStatusUpdate('cancel')} ><Iocn_Cancel /> Cancel</Button>
						<Popup className="processingcall-modal"
						trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
					}
					modal
					nested
					>
					{close => (
						<div className="modal interpreter">
						<div className="header"> We will connect you to an
						interpreter soon. </div>
						<div className="content special-svg">
						{' '}
						<Icon_time />
						</div>
						<div className="actions">
						<Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
						</div>
						</div>
						)}
						</Popup>

						</div>
						</div>

						<Popup className="processingcall-modal language"
							modal
							open={Showlangpopup}
							closeOnDocumentClick 
							onClose={closeModal}
						>
						    <div className="modal interpreter">
							   <button className="close" onClick={()=>Languagecancel()}>
							      <Icon_closegrey />
							   </button>
							   <div className="header"> Select language </div>
							   <div className="content special-svg">
							      {' '}
							      <div className="form-div">
							         <label>Language</label>
							         <select
							            onChange={(e) => changedropdownvalue(e,'language')}
							            
							            name="language"
							            className="language"
							            id="language"
							            value={formdata.language}
							            >
							            <option value="default" selected>Select language of the person</option>
							            {
							            languages.map(function(language,i){
							            return (
							            <option value={language._id}>{language.language}</option>
							            )
							            })
							            }
							         </select>

							      </div>
							   </div>
							   <Typography className="customerror">{Showerror ? 'please select language of the person':''}</Typography>
							   <div className="actions">
							      <Button className="primary" onClick={()=>chosedlanguage()}>Done</Button>
							   </div>
							</div>
						</Popup>
			            <Popup className="processingcall-modal no-interpreter"
						
							modal
							open={Showintavailbale}
							closeOnDocumentClick 
							onClose={closeModal}
						>
							<div className="modal interpreter">
							   <button className="close" onClick={closeModal}>
							      <Icon_closegrey />
							   </button>
							   <div className="header"> No Interpreter Available </div>
							</div>
						</Popup>

						</div>
					</div>
				</div>
			</div>
			)	
	}else{
		return (<>{is_notconnected ?
			<Box className="full-screen"><div id="meeting-container-id"></div></Box>
		:
			<NotconnectedCall selectedmember={selectedmember} doselectedmember={doselectedmember}/>	
		}
		</>
		)
	}
}

export default ProccessingCall;