import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as Vector } from "../../Assets/icon.svg";
import man from "../../Assets/man.png";
import group from "../../Assets/group.png";
import deaf from "../../Assets/deaf.png";
import hear from "../../Assets/hear.png";
import { NavLink, useNavigate } from "react-router-dom";
import RegisterForm from "./RegisterForm";
const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    paddingHorizontal: 40,
  },
  inputContainer: {
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 15,
    marginTop: 10,
    borderRadius: 8,
    borderColor: "#D0E1F4",
    border: "2px solid ",
    borderWidth: 2,
    marginRight: 10,
    // height: 60,
    // width: '80%'
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(42, 84, 250)",
    width: "136px",
    height: "86px",
    borderRadius: "5px",
    paddingTop: "2px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "45px",
    marginRight: "8px",
    marginLeft: "6px",
  },
  inputLabel: {
    paddingTop: 5,
    fontSize: "14px",
    color: "#5490D6",
    marginBottom: "2px",
    textAlign: "center",
    marginTop: "30px",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "18px",
  },
  input: {
    color: "#515151",
    fontWeight: "bold",
    fontSize: 14,
    marginRight: 10,
    marginTop: -5,
    flex: 1,
    // width:"397px",
    textAlign: "center",
  },
  mainDiv: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "white",
    opacity: "1",
    padding: "20",
    height: "341px",
    width: "625px",
    borderRadius: "8px",
  },
  div1: {
    height: "190px",
    width: "343px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  div2: {
    height: "190px",
    width: "343px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    position: "absolute",
    width: "50px",
    height: "60px",
    top: "-39px",
    marginLeft: "38px",

    // zIndex: -1,
  },
  image1: {
    position: "absolute",
    width: "110px",
    height: "60px",
    top: "-39px",
    marginLeft: "15px",
  },
  image2: {
    position: "absolute",
    width: "55px",
    height: "60px",
    top: "-39px",
    marginLeft: "48px",
  },
  container: {
    position: "relative",
  },
  mainCenter: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  signText: {
    fontWeight: "bolder",
    fontSize: "38px",
    color: "#2760A3",
    textAlign: "center",
  },
  ableText: {
    display: "center",
    fontSize: "38px",
    color: "#FF9D00",
    fontWeight: "bolder",
    textAlign: "center",
  },
}));
const Register = ({ label, ...props }) => {
  const classes = useStyles();
  const [isColor, setColor] = useState(false);
  const [istextColor, setTextColor] = useState(false);
  const [isColor1, setColor1] = useState(false);
  const [istextColor1, setTextColor1] = useState(false);

  const [isColor2, setColor2] = useState(false);
  const [istextColor2, setTextColor2] = useState(false);

  const [isColor3, setColor3] = useState(false);
  const [istextColor3, setTextColor3] = useState(false);

  const [isIndivisual, setisIndivisual] = useState(true);
  const [iscorporate, setiscorporate] = useState(false);

  const [canHear, setcanHear] = useState(false);
  const [cannotHear, setcannotHear] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();

  const [is_registerform, setRegisterForm] = useState(false);
  const [isUserTypeSelected, setIsUserTypeSelected] = useState(false);
  const colorChange = () => {
    setColor("blue");
  };

  return !is_registerform ? (
    <Box xs={6} sm={6} md={12} lg={12} className="register-main-container">
      <Grid style={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Vector style={{ width: "140px", height: "115px" }} />

        <div>
          <span className={classes.signText}>Sign</span>
          <span className={classes.ableText}>Able</span>
        </div>
      </Grid>
      <Grid style={{ marginTop: "2%", paddingLeft: 15 }}>
        <span className="register-text-2">Register</span>
      </Grid>
      {/* {
        isUserTypeSelected ?(
        <Grid className={classes.mainCenter} id="reg_inner_container">
          <Paper className={classes.mainDiv}>
            <div className={classes.inputContainer}>
              <span className='register-i-am-text'>Hearing ability</span>
              <div className={classes.div2}>
                <div className={classes.row} style={{ backgroundColor: canHear ? '#2760A3' : 'white' }}
                  onClick={() => {
                    // setcanHear(true)
                    // setcannotHear(false)
                    setcanHear(false)
                    setcannotHear(true)
                    // if () {

                      // navigate('/register-form-corporate');
                      setRegisterForm(true)
                    // }
                  }}
                >
                  <span className={classes.container}>
                    <img className={classes.image2} src={deaf} />
                  </span>
                  <Typography className={classes.inputLabel} style={{ alignContent: "center", color: canHear ? '#ffffff' : '#2760A3' }}>I cannot Hear</Typography>
                </div>

                <div className={classes.row}
                  style={{ backgroundColor: cannotHear ? '#2760A3' : 'white' }}
                  onClick={() => {
                    // setcanHear(false)
                    // setcannotHear(true)
                    setcanHear(true)
                    setcannotHear(false)
                    // if (isIndivisual || iscorporate) {
                      // <NavLink to="/register-form"> </NavLink>
                      // navigate('/register-form');
                      setRegisterForm(true)
                    // }
                  }}
                >
                  <span className={classes.container}>
                    <img className={classes.image2} src={hear} />
                  </span>
                  <Typography className={classes.inputLabel} style={{ alignContent: "center", color: cannotHear ? '#ffffff' : '#2760A3' }}>I can Hear</Typography>
                </div>

              </div>
            </div>
          </Paper>
        </Grid>
        ):(
          <Grid className={classes.mainCenter} id="reg_inner_container">
          <Paper className={classes.mainDiv}>
            <div className={classes.inputContainer}>
              <span className='register-i-am-text'>I am</span>
              <div className={classes.div2}>
                <div className={classes.row} style={{ backgroundColor: canHear ? '#2760A3' : 'white' }}
                  onClick={() => {
                    // setcanHear(true)
                    // setcannotHear(false)
                    // setcanHear(false)
                    // setcannotHear(true)
                    // if () {

                      // navigate('/register-form-corporate');
                      // setRegisterForm(true)
                      setisIndivisual(true)
                      setiscorporate(false)
                      setIsUserTypeSelected(true)
                    // }
                  }}
                >
                  <span className={classes.container}>
                    <img className={classes.image2} src={deaf} />
                  </span>
                  <Typography className={classes.inputLabel} style={{ alignContent: "center", color: canHear ? '#ffffff' : '#2760A3' }}>Individual</Typography>
                </div>

                <div className={classes.row}
                  style={{ backgroundColor: cannotHear ? '#2760A3' : 'white' }}
                  onClick={() => {
                    // setcanHear(false)
                    // setcannotHear(true)
                    // setcanHear(true)
                    // setcannotHear(false)
                    // if (isIndivisual || iscorporate) {
                      // <NavLink to="/register-form"> </NavLink>
                      // navigate('/register-form');
                      // setRegisterForm(true)
                      setisIndivisual(false)
                      setiscorporate(true)
                      setIsUserTypeSelected(true)
                    // }
                  }}
                >
                  <span className={classes.container}>
                    <img className={classes.image2} src={hear} />
                  </span>
                  <Typography className={classes.inputLabel} style={{ alignContent: "center", color: cannotHear ? '#ffffff' : '#2760A3' }}>Corporate</Typography>
                </div>

              </div>
            </div>
          </Paper>
        </Grid>
        )
        } */}
      {/* /////////////////////////////////////////////////////////////////////////////// */}
      <Grid className={classes.mainCenter} id="reg_inner_container">
        <Paper className={classes.mainDiv}>
          <div className={classes.inputContainer}>
            <span className="register-i-am-text">Hearing ability</span>
            <div className={classes.div2}>
              <div
                className={classes.row}
                style={{ backgroundColor: canHear ? "#2760A3" : "white" }}
                onClick={() => {
                  // setcanHear(true)
                  // setcannotHear(false)
                  setcanHear(false);
                  setcannotHear(true);
                  // if () {

                  // navigate('/register-form-corporate');
                  setRegisterForm(true);
                  // }
                }}
              >
                <span className={classes.container}>
                  <img className={classes.image2} src={deaf} />
                </span>
                <Typography
                  className={classes.inputLabel}
                  style={{
                    alignContent: "center",
                    color: canHear ? "#ffffff" : "#2760A3",
                  }}
                >
                  I cannot Hear
                </Typography>
              </div>

              <div
                className={classes.row}
                style={{ backgroundColor: cannotHear ? "#2760A3" : "white" }}
                onClick={() => {
                  // setcanHear(false)
                  // setcannotHear(true)
                  setcanHear(true);
                  setcannotHear(false);
                  // if (isIndivisual || iscorporate) {
                  // <NavLink to="/register-form"> </NavLink>
                  // navigate('/register-form');
                  setRegisterForm(true);
                  // }
                }}
              >
                <span className={classes.container}>
                  <img className={classes.image2} src={hear} />
                </span>
                <Typography
                  className={classes.inputLabel}
                  style={{
                    alignContent: "center",
                    color: cannotHear ? "#ffffff" : "#2760A3",
                  }}
                >
                  I can Hear
                </Typography>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
      {/* /////////////////////////////////////////////////////////////////////////////// */}
    </Box>
  ) : (
    <RegisterForm
      isIndivisual={isIndivisual}
      iscorporate={iscorporate}
      canHear={canHear}
      cannotHear={cannotHear}
      setRegisterForm={setRegisterForm}
    />
  );
};
export default Register;
