import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner_150 from "../../Assets/Spinner-1s-150px.svg";
import { postDataFromApi, getDataFromApi, putDataFromApi } from '../../Service/CommonService';
import { getprofile, getAuth } from '../../Service/Helper';
import moment from 'moment';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Two_user from "../../Assets/two_user.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../Assets/conference-user.svg";
import Conference_user_remove from "../../Assets/conference-user-remove.svg";
import { ReactComponent as Icon_teluguicon } from "../../Assets/telugu-icon.svg";
import { ReactComponent as Icon_whitevideocall } from "../../Assets/white-video-call.svg";
import Submit_btn from "../../Assets/call-a-person-main-form-v-call.png";
import leftIcon from "../../Assets/Left.png"
import ProccessingCall from './ProcessingCall';
import { getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails,CallSetupForSupport,custoclearinterval,BaseUrl,CallStatusUpdateData} from "../../Service/videoskp";
import {
	Routes,
	Route,
	NavLink,
	useNavigate ,
	HashRouter
} from "react-router-dom";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import $ from 'jquery';


const CallHistory_page = (props) => {
	const [is_loaded, setLoaded] = useState(false)
	const [is_notconnected,setNotconnected]=useState(false)
	const [is_selected,setSelected]=useState(false)
	const [is_preccessed,setProccessed]=useState(false)
	const [isvideocallstarted,setisvideocallstarted]=useState(false)
	const [mycallHistory, setmycallHistory] = useState([])
	const [calldeatils, setcalldeatils] = useState(false)
	const [callAttendeeDetails, setcallAttendeeDetails] = useState([])
	const [selectedmember,setselectedmember]=useState({member:[],contactdetails:[]})
	const [CallResponseData,setCallResponseData] = useState({})
    const [intervalId, setIntervalId] = useState(false);
	const [clickedcall, setclickedcall] = useState({})
	const [newIntervalId,setnewIntervalId]=useState('');
	const [formdata,setFormData]=useState({language:"1"})
	const [isCallReceived,setisCallReceived] = useState(false)
	const[token,settoken]=useState([])
	const[MeetingData,setMeetingData]=useState([])
	const[MeetingId,setMeetingId]=useState('')
	const navigate = useNavigate();
	const contactDetails = [];
	const authdata = getAuth()
	//const userid=8;
	const userid = authdata['_id'];
	const [query,setQuery]=useState("");
	

	var processed=false;
    var singlenewIntervalId  ="";

	const doselectedmember=(contact,index,page="")=>{
    	console.log('contact',contact)
    	
		let selected=selectedmember.member
		let singlecontactdetails=selectedmember.contactdetails
		if(selected.includes(contact._id)){
			const i = selected.indexOf(contact._id);
			if (i > -1) {
				selected.splice(i, 1);
				singlecontactdetails.splice(i,1)
			}
		}
		else{
			selected.push(contact._id)
			singlecontactdetails.push(contact)
		}
		console.log(selected)
		if(page == 'ViewSelectedTabs'){

			if(selected.length==0){
				setSelected(false)

			}
		}
		setselectedmember((formData) => ({
            ...formData,
			member:selected,
			contactdetails:singlecontactdetails
        }));
		setNotconnected(true)
		console.log('selectedmember',selectedmember)

	}
	const gettoken = async () => {
		var query = ""
		const token = await getToken();
		console.log('get token',token)
		if(token && token!=null){
			settoken(token);
			getMeetingData(token);

		}
		
	}

	const getMeetingData = async (token) => {
		var query = ""
		const response = await createMeeting(token);
		console.log('response',response)
		if(response &&response!=null){
			setMeetingData(response)
			setMeetingId(response.meetingId)
			console.log(response.meetingId)
	          //startmeeting(response.meetingId)
	          settingCall(response)
	      }


	}

	const settingCall = async (data) => {
		console.log('data',data)
		var contactDetailsArray=[];

		contactDetailsArray=[{
			mobile_number:authdata['mobile_number'],
	  		name:authdata['first_name'],

	  	}]

	  	var inputData = JSON.stringify({
			"user_id": userid,
			"call_type": "1",
			"language_id": formdata.language ? formdata.language : authdata['language_id'],
			"purpose_id": "1",
			"meetingDetails": data,
			"contactDetails": contactDetailsArray,
			"user_type": authdata['user_type'],
			"individual_user_type": authdata['user_category'],
			"is_same_gender": "0",
			"initiator_gender": authdata['gender'],
			"initiatorFullName": authdata['first_name'],
			"source":"web"
	  	})
		  

	  	console.log('inputData',inputData)

	  	/* call setup API */

	  	const callResponse = await callSetup(inputData);
	  	setCallResponseData(callResponse)
		//   setProccessed(true)
	  	console.log('callResponse',callResponse)
		//console.log('callResponse',callResponse.data.meetingId)

		if(callResponse && callResponse.code){
			if(!intervalId){
				setTimeout(function () {
					if(!isCallReceived){
						CallStatusUpdateData(callResponse.data._id,'7','6')	
						clearInterval(singlenewIntervalId);
						setIntervalId(true);
					
						setNotconnected(true)
					}
				}, 30000);
				singlenewIntervalId=setInterval(function () {
					console.log('every 3 seconds')
					startTimer(callResponse)
				}, 3000);
				setnewIntervalId(singlenewIntervalId)
			}
			//clearInterval(singlenewIntervalId);
			/*if (intervalId) {
		      clearInterval(singlenewIntervalId);
		      
		      console.log("  cc clear")
		      
		  }*/


		}
	}
    
    const startTimer = async (data) => {
		/*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
		console.log('console.log(response)',response1)*/
		const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'],authdata['user_type'], token);
		console.log('startTimer',response)

		if (response && response.data !== "data not found") {
			console.log(newIntervalId);
			// response.data.status = 2
			if (response.data.status == 2) {
				if(!processed){
					processed=true;
					clearInterval(newIntervalId);
					setIntervalId(true);
					CallStatusUpdateData(data.data._id,'3','3')
					startmeeting(data.data.meetingId,data.data._id)
					setisCallReceived(true)
				}
			}
		}


	}	

	const startmeeting=async(meetingId,callSetupid)=>{
		localStorage.setItem('callSetupid', callSetupid)
	    //here call start meeting api before initiate meeting our own api will be call
	    const config = {
	    	name: authdata['first_name'],
	    	meetingId: meetingId,
	    	apiKey:sdkAPIKey,
	    	// joinScreen: {
	    	// 	visible: false,

	    	// },
	    	containerId: "meeting-container-id",
	    	micEnabled: true,
	    	webcamEnabled: true,
	    	participantCanToggleSelfWebcam: true,
	    	participantCanToggleSelfMic: true,

	    	chatEnabled: true,
	    	screenShareEnabled: true,
	    	canChangeLayout: true,
			redirectOnLeave:window.location.origin,
	    	// redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
	    	joinScreen: {
	    		visible: false,
	    	},
	    };

	    const meeting = new VideoSDKMeeting();
	    meeting.init(config);
	   	$(".full-screen").insertAfter(".page-main");
	   	
	    setisvideocallstarted(true)
	   	setNotconnected(true)

	}

	const CallStatusUpdate = async (buttonType)=>{

		clearInterval(newIntervalId);
		setIntervalId(true);

		if(buttonType=='cancel'){
			console.log('CallResponseData as',CallResponseData)
			const response = await CallStatusUpdateData(CallResponseData.data._id,'6','6');
			console.log('cancel',response)
			if(response && response.code){
				setNotconnected(false)
				setNotconnected(false)
				setProccessed(false)
				setSelected(false)
				clearInterval(singlenewIntervalId);
				navigate('/Home')

			}

		}else if(buttonType=='callback'){
			const response = await CallStatusUpdateData(CallResponseData.data._id,'5','6');
			console.log(response)
		}
	}

    function callbackrequest(){
		//clearInterval(newIntervalId);
		//setIntervalId(true);
		navigate('/Home')
	}
	const getMyCalltHistory = async () => {
		setLoaded(true);
		console.log("user_id----->",userid)
		var query = "user_id=" + userid
		const response = await postDataFromApi('callHistoryNew', query);
		console.log("callHistoryNew response----->",response.data.data)
		// console.log(userid)
		if (response.data.code && response.data.data != null) {
			setmycallHistory(response.data.data);
		}
		setLoaded(false);

	}
	const Timediffrence = (startdate, enddate) => {
		if (startdate != '' && startdate != null && startdate !== 'undefined' && enddate != '' && enddate != null && enddate !== 'undefined' ) {
			const startTime = moment(startdate)
			const EndTime = moment(enddate)
			var diffDurationSeconds = EndTime.diff(startTime, 'seconds')
			var diffDurationMinutes = new Date(diffDurationSeconds * 1000).toISOString().substr(11, 8)
			var split_time = diffDurationMinutes.split(':')
			var time = split_time[0] + ': ' + split_time[1] + ' : ' + split_time[2]
			// console.log('time', time)
		}
		else {
			var time = ""
		}
		return time
	}

	const getcallAttendeeDetails = async (call_id) => {

		var query = ''
		const response = await getDataFromApi('getcallAttendeeDetails/' + call_id, query);
		console.log('callAttendeeDetails callid------>', call_id)
		console.log('callAttendeeDetails------>', response)

		if (response.data.code && response.data.data != null) {
			setcalldeatils(true)
			setcallAttendeeDetails(response.data.data)
			

		}


	}
	const CallAgain= async (e) => {
		//  alert('hi')
		// setProccessed(true)
		setNotconnected(true)
		console.log(formdata.language)
		// if(formdata.language){
		//    gettoken();
		// }
	}

	function handleClickAttendee(call_id, index) {
		setclickedcall(mycallHistory[index])
		console.log('myclickdata', mycallHistory[index])
		console.log('call_id', call_id)
		getcallAttendeeDetails(call_id)
		
	}

	// var today=moment(new Date()).format('Y-MM-DD');
	var today = 0;
	var yesterday = moment(new Date() - 1).format('Y-MM-DD');

	useEffect(() => {

		getMyCalltHistory();
		// gettoken();
		// getlanguages();
	}, []);

	// console.log("query",query)
	const querydata=mycallHistory.filter(mycallHistory=>mycallHistory.initiator_full_name.toLowerCase().includes(query.toLowerCase()))
	console.log("querydata------>",querydata)

	


	return (
		<>
		{!is_notconnected ? 
		(<div className="page-main">
			<div className="page-main-inner">
				
				<Grid container spacing={3} className="main-grid historygrid">
					<Grid item md={12} className="call-person-profile">
						<div className="box-shadow text-center radius-8px" style={{ height: "100%" }}>
							<div className="call-person-tab-header">
								<h3>Call History</h3>
							</div>
							<Grid container spacing={3} className="main-grid callhistory">
								<Grid item md={calldeatils ? 7 : 12} className="">
									<div className="call-history-inner">
										<div className="call-person-search-form">
											<form>
												<label>
													<img src={SearchIcon} />
													<input
														type="text"
														name="search-contacts"
														className="search-contacts"
														id="search-contacts"
														placeholder="Search from Call History"
														onChange={(e)=>{setQuery(e.target.value)}}
													/>
												</label>
												<input type="submit" id="search-contacts-btn" />
											</form>
										</div>
										<div className="call-history-detail vertical_scrollbar">
											{is_loaded ? <img className="loading_spinner" src={Spinner_150} /> : ''}


											{
												(querydata.length > 0) ?
												querydata.map(function (contact, i) {

														var initiatornames = [];
														var newtoday = today
														if (contact.call_start_time && (today || today != moment(contact.call_start_time).format('Y-MM-DD'))) {
															today = moment(contact.call_start_time).format('Y-MM-DD')
														}/*else if(contact.call_start_time && (today && today==moment(contact.call_start_time).format('Y-MM-DD'))){
                                                        today='Today'
						                 			}else if(contact.call_start_time && (yesterday && yesterday==moment(contact.call_start_time).format('Y-MM-DD'))){
                                                        today='Yesterday'
						                 			}*/
														return (
															<>
																<div className="call-date" id={contact._id}>
																	{/* {today!=newtoday ? <> {today==moment().format('Y-MM-DD') ? 'Today' :  (today==moment(new Date()).add(-1, 'days').format('Y-MM-DD') ? 'Yesterday' : today) }</> : ''} */}
																	{today !== newtoday ? today : ''}
																</div>
																<div className={clickedcall && clickedcall._id == contact._id ? 'single_callhistory active_callhistory' : 'single_callhistory'} key={'mycontacts-' + i} onClick={() => handleClickAttendee(contact._id, i)}>

																	<Grid item md={2}>
																		{/*<span className="imgaes">

										                            <img src={Contact_img} className="profile_intial"/>
										                            <img src={Contact_img} className="profile_intial"/>
										                            <img src={Contact_img} className="profile_intial"/>

										                        </span>*/}
																		<p className="single_profinitial">{getprofile(contact.initiator_full_name)}</p>
																	</Grid>

																	<Grid item md={3}>

																		<span className="call_names_inter">
																			{/* <span className="callhistory_names">Arundhati, Ramya, Jane...</span> */}
																			<span className="callhistory-initiator-name"> 
																			{contact.initiator_full_name}</span>
																		</span>
																	</Grid>
																	<Grid item md={3}>

																		<div className="content">{today}</div>
																	</Grid>
																	<Grid item md={2}>
																		{Timediffrence(contact.call_start_time, contact.call_end_time) ? Timediffrence(contact.call_start_time, contact.call_end_time):"-"}
																	</Grid>
																</div>
															</>
														);
													})
													:
													(<div className="single_contact">
														<p className="nodata_found">No Data Found</p>

													</div>)
											}

										</div>
									</div>
								</Grid>
								{calldeatils ? (

									<Grid item md={5} className="">

										<div className="call-person-tab-content">
											<div className="call-person-tab-content-inner">
												<div>
													<a className="backbtn-design" href="javascript:" onClick={() => setcalldeatils(false)} >
														<img src={leftIcon} style={{ width: "15px" }} />
													</a>
												</div>
												<div className="call-person-tab-header">


													<h3>Call Details</h3>

												</div>

												<div className="call_details_inner time">
													<div className="time">
														<div className="smalltitle">
															Time
														</div>
														<div className="content">{clickedcall.call_start_time ? moment(clickedcall.call_start_time).format('Y-MM-DD, HH:mm:ss a') : ''}</div>
													</div>
													<div className="duration">
														<div className="smalltitle">
															Duration
														</div>
														<div className="content">{Timediffrence(clickedcall.call_start_time, clickedcall.call_end_time)?Timediffrence(clickedcall.call_start_time, clickedcall.call_end_time): ""}</div>
													</div>
												</div>
												<div className="call_details_inner">
													<div className="smalltitle">
														Participants:
													</div>
													{callAttendeeDetails.map((callAttendeeDetails, index) => (

														<div className="history-persons">

															<div className="call-person-img">
																<p className="single_profinitial">{getprofile(callAttendeeDetails.user_docs && callAttendeeDetails.user_docs[0] ? callAttendeeDetails.user_docs[0].first_name + ' ' + callAttendeeDetails.user_docs[0].last_name : (callAttendeeDetails.hearing_person_name ? callAttendeeDetails.hearing_person_name : 'NA'))}</p>
															</div>

															<div className="history-person-name content ">
																<p>{callAttendeeDetails.user_docs && callAttendeeDetails.user_docs[0] ? callAttendeeDetails.user_docs[0].first_name + ' ' + callAttendeeDetails.user_docs[0].last_name : (callAttendeeDetails.hearing_person_name ? callAttendeeDetails.hearing_person_name : 'NA')}</p>
															</div>

                                                        	<div className="callhistory-participants-time">{Timediffrence(callAttendeeDetails.call_start_date_time, callAttendeeDetails.call_end_date_time) ? Timediffrence(callAttendeeDetails.call_start_date_time, callAttendeeDetails.call_end_date_time):"-"}</div>


														</div>
													))}

												</div>
												{/* <div className="call_details_inner">
										    <div className="smalltitle">
												Language
											</div>
											<div className="content">{clickedcall.language_id ? clickedcall.language_id.language : '-'}</div>
										</div>
										
										<div className="call_details_inner">
										    <div className="smalltitle">
												Purpose as mentioned by Interpreter
											</div>
											<div className="content">Personal</div>
										</div>
										
										 */}
										<Button className="primary" variant="text" style={{ marginTop: "10%" }} onClick={()=>CallAgain()}><Icon_whitevideocall /> call Again</Button>
											</div>
										</div>
									</Grid>) : ''}

							</Grid>
						</div>

					</Grid>
				</Grid>
			</div>
		</div>
		)
				: 
		(<ProccessingCall setNotconnected={setNotconnected} selectedmember={selectedmember} doselectedmember={doselectedmember} setProccessed={setProccessed} setSelected={setSelected} setisvideocallstarted={setisvideocallstarted} callAttendeeDetails={callAttendeeDetails}/>)}
		</>
	)
}

export default CallHistory_page;